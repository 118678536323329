
import { computed, defineComponent, ref } from "vue";
import { ConfigService } from "@/services/config-service";
import { db } from "@/database/market-db";
import { Config } from "@/models/base/config.model";
import router from "@/router";

export default defineComponent({
  name: "Home",
  setup() {
    const username = ref("");
    const storename = ref("");
    const configService = new ConfigService(db);

    /**
     * Gets the greeting message according to current time
     */
    const greeting = computed(() => {
      const now = new Date().getHours();
      if (now < 6) {
        return "Boa noite";
      } else if (now < 12) {
        return "Bom dia";
      } else if (now < 18) {
        return "Boa tarde";
      } else {
        return "Boa noite";
      }
    });

    configService.get().then((result: Config | undefined) => {
      if (result) {
        username.value = result.username;
        storename.value = result.storename;
      }
    });

    return { username, storename, greeting, router };
  },
});
