
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import { Toast } from "bootstrap";

export default defineComponent({
  name: "Toast",
  props: {
    toastId: { type: String, required: true },
  },
  setup(props) {
    const message = ref<string | null>(null);
    let toast: Toast | null;

    onMounted(() => {
      const toastElement = document.getElementById(props.toastId);
      toast = toastElement ? new Toast(toastElement) : null;
    });

    onUnmounted(() => {
      if (toast) {
        toast.dispose();
      }
    });

    /**
     * Shows the toast
     * @param messageData the message to show in the toast
     */
    const open = (messageData: string): void => {
      if (toast) {
        message.value = messageData;
        toast.show();
      } else {
        throw new Error("Could not find the toast to show");
      }
    };

    /**
     * Hides the toast
     */
    const close = (): void => {
      if (toast) {
        toast.hide();
      } else {
        throw new Error("Could not find the toast to hide");
      }
    };

    // expose template variables
    return { message, open, close };
  },
});
