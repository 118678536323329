import { Category } from './category.model';
import { SelectableItem } from './selectable-item.interface';
/**
 * Class that represents a tab that can be rendered in a tab container
 */
export class Tab implements SelectableItem {
  id: number;
  name: string;

  /**
   * Constructor
   */
  constructor(name: string, id: number) {
    this.id = id;
    this.name = name;
  }

  static fromCategory(category: Category): Tab {
    if (category.id == null) {
      throw new Error("The category must have a valid ID to be converted to a Tab object");
    }

    return new Tab(
      category.name,
      category.id
    );
  }
}
