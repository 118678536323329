import { BaseService } from './base/base-service';
import { MarketDb } from "@/database/market-db";
import { Sale } from '../models/base/sale.model';

/**
 * Class that represents a collection of methods related to sale operations
 */
export class SaleService extends BaseService {
  /**
   * Constructor
   */
  constructor(db: MarketDb) {
    // call super
    super(db);
  }

  /**
   * Gets all sales from the database
   * @returns a promise that resolves to a list of all sales
   */
  public async getAll(): Promise<Sale[]> {
    return this.db.sales.toArray();
  }

  /**
   * Gets all sales from an specified date
   * @returns a promise that resolves to a list of all sales from the specified date
   */
  public async getFromDay(date: Date): Promise<Sale[]> {
    const lower = new Date();
    lower.setHours(0, 0, 0, 0);

    const upper = new Date();
    upper.setHours(0, 0, 0, 0);
    upper.setDate(date.getDate() + 1);

    return this.db.sales.where('date').between(lower, upper).toArray();
  }

  /**
   * Gets all sales from today
   * @returns a promise that resolves to a list of all sales from today
   */
  public async getToday(): Promise<Sale[]> {
    const lower = new Date();
    lower.setHours(0, 0, 0, 0);

    const upper = new Date();
    upper.setHours(0, 0, 0, 0);
    upper.setDate(upper.getDate() + 1);

    return this.db.sales.where('date').between(lower, upper).toArray();
  }

  /**
   * Gets all sales from yesterday
   * @returns a promise that resolves to a list of all sales from yesterday
   */
  public async getYesterday(): Promise<Sale[]> {
    const lower = new Date();
    lower.setHours(0, 0, 0, 0);
    lower.setDate(lower.getDate() - 1);

    const upper = new Date();
    upper.setHours(0, 0, 0, 0);

    return this.db.sales.where('date').between(lower, upper).toArray();
  }

  /**
   * Gets all sales from the day before yesterday
   * @returns a promise that resolves to a list of all sales from the day before yesterday
   */
  public async getDayBeforeYesterday(): Promise<Sale[]> {
    const lower = new Date();
    lower.setHours(0, 0, 0, 0);
    lower.setDate(lower.getDate() - 2);

    const upper = new Date();
    upper.setHours(0, 0, 0, 0);
    upper.setDate(lower.getDate() - 1);

    return this.db.sales.where('date').between(lower, upper).toArray();
  }

  /**
   * Gets all sales from the current week
   * @returns a promise that resolves to a list of all sales from the current week
   */
  public async getCurrentWeek(): Promise<Sale[]> {
    const lower = new Date();
    const dayOfWeek = lower.getDay();
    lower.setHours(0, 0, 0, 0);
    lower.setDate(lower.getDate() - dayOfWeek);

    const upper = new Date();
    upper.setHours(0, 0, 0, 0);
    upper.setDate(lower.getDate() + 7 - dayOfWeek);

    return this.db.sales.where('date').between(lower, upper).toArray();
  }

  /**
   * Gets all sales from the last month
   * @returns a promise that resolves to a list of all sales from the last month
   */
  public async getLastMonth(): Promise<Sale[]> {
    const lower = new Date();
    lower.setDate(1);
    lower.setMonth(lower.getMonth() - 1)
    lower.setHours(0, 0, 0, 0);

    const upper = new Date();
    upper.setDate(1);
    upper.setHours(0, 0, 0, 0);

    return this.db.sales.where('date').between(lower, upper).toArray();
  }

  /**
   * Gets all sales from the current month
   * @returns a promise that resolves to a list of all sales from the current month
   */
  public async getCurrentMonth(): Promise<Sale[]> {
    const lower = new Date();
    lower.setDate(1);
    lower.setHours(0, 0, 0, 0);

    const upper = new Date();
    upper.setDate(1);
    upper.setMonth(upper.getMonth() + 1)
    upper.setHours(0, 0, 0, 0);

    return this.db.sales.where('date').between(lower, upper).toArray();
  }

  /**
   * Gets all sales from an specified month
   * @returns a promise that resolves to a list of all sales from the specified month
   */
  public async getFromMonth(date: Date): Promise<Sale[]> {
    const lower = new Date();
    lower.setDate(1);
    lower.setHours(0, 0, 0, 0);

    const upper = new Date();
    upper.setDate(1);
    upper.setMonth(date.getMonth() + 1)
    upper.setHours(0, 0, 0, 0);

    return this.db.sales.where('date').between(lower, upper).toArray();
  }

  /**
   * Posts or Puts a new sale into the database
   * @param sale the sale to be posted
   * @returns a promise that resolves to the generated or updated ID
   */
  public async postOrPut(sale: Sale): Promise<number> {
    return this.db.sales.put(sale);
  }

  /**
   * 
   * @param id the id of the sale to be deleted
   * @returns returns an empty promise
   */
  public async delete(id: number): Promise<void> {
    return this.db.sales.delete(id);
  }
}
