<template>
  <RouterView />
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "App",
});
</script>

<style lang="scss">
@import "styles/global";
@import "styles/fonts";

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#app {
  height: 100%;
}

.mt-header {
  margin-top: 3.5rem;
}

.fadein {
  animation: fadein 150ms ease;
}
</style>
