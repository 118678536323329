
import { computed, defineComponent, PropType } from "vue";
import { Filter } from "@/models/base/filter.model";
import { Sale } from "@/models/base/sale.model";
import { numberToMoney } from "@/utils/util";

export default defineComponent({
  name: "SalesSummary",
  props: {
    revenueToday: { type: Number },
    revenueThisMonth: { type: Number },
    revenueLastMonth: { type: Number },
    cashRevenue: { type: Number },
    cardRevenue: { type: Number },
    transferRevenue: { type: Number },
    currentDateFilter: { type: Object as PropType<Filter<Sale>> },
    currentTypeFilter: { type: Object as PropType<Filter<Sale>> },
  },
  setup(props) {
    /**
     * Evaluates the proportion of each type of sale
     */
    const proportions = computed(() => {
      const partialMonth =
        (props.revenueThisMonth ?? 0) / (props.revenueLastMonth ?? 0);
      const cash = (props.cashRevenue ?? 0) / (props.revenueThisMonth ?? 0);
      const card = (props.cardRevenue ?? 0) / (props.revenueThisMonth ?? 0);
      const transfer =
        (props.transferRevenue ?? 0) / (props.revenueThisMonth ?? 0);
      return { cash, card, transfer, partialMonth };
    });

    // expose template variables
    return { numberToMoney, proportions };
  },
});
