<template>
  <main class="d-flex flex-column flex-fill overflow-hidden">
    <!-- Title section -->
    <section class="px-3 py-3 d-flex">
      <!-- Add input button -->
      <button
        class="btn btn-primary me-3 text-nowrap"
        @click="openEstimateModalForCreation()"
      >
        <i class="bi bi-plus-circle me-lg-2"></i>
        <span class="d-none d-lg-inline">Novo Orçamento</span>
      </button>

      <!-- Input value -->
      <div class="input-group">
        <span class="input-group-text" id="value-input">
          <span>@</span>
          <span class="d-none d-lg-inline">Pesquisar</span>
        </span>
        <input
          type="text"
          class="form-control"
          placeholder="Digite um termo de pesquisa"
          aria-label="value-input"
          aria-describedby="value-input"
          v-model="query"
          v-focus
          @input="handleQueryInput()"
        />
        <button class="btn btn-primary">
          <i class="bi bi-search me-lg-2"></i>
          <span class="d-none d-lg-inline">Pesquisar</span>
        </button>
      </div>
    </section>

    <!-- Tabular data -->
    <section class="px-3 pb-2 d-flex flex-column flex-fill overflow-auto">
      <EstimateTable
        :estimates="estimates"
        @onEdit="openEstimateModalForEdition($event)"
        @onDelete="openConfirmationModalForDeletion($event)"
      />
    </section>

    <!-- Modal to create or edit customers -->
    <EstimateModal
      ref="estimateModal"
      :modalId="'estimateModal'"
      @created="handleEstimateCreation()"
      @updated="handleEstimateUpdate()"
    />

    <!-- Modal to delete customers -->
    <ConfirmationModal
      ref="confirmationModal"
      :modalId="'confirmationModal'"
      :title="'Excluir cliente'"
      :confirmationLabel="'Confirmar'"
      @confirmed="handleEstimateDeletion()"
    >
      <div>
        <div>
          O orçamento associado ao cliente
          <strong>{{ estimateCustomerToDelete }}</strong> será excluído.
        </div>
        <div>Esta ação é irreversível. Deseja continuar?</div>
      </div>
    </ConfirmationModal>

    <!-- Toast that handles feedback from operations -->
    <Toast ref="feedbackToast" :toastId="'toast'" />
  </main>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { ModalMode } from "@/models/enums/modal-mode.enum";
import { db } from "@/database/market-db";
import { EstimateService } from "@/services/estimate-service";
import { Estimate } from "@/models/base/estimate.model";
import EstimateTable from "@/components/tables/EstimateTable.vue";
import EstimateModal from "@/components/modals/EstimateModal.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import Toast from "@/components/base/Toast.vue";

export default defineComponent({
  name: "Estimates",
  components: {
    EstimateTable,
    EstimateModal,
    ConfirmationModal,
    Toast,
  },
  setup() {
    const query = ref("");
    const estimates = ref(new Array<Estimate>());
    const estimateService = new EstimateService(db);
    const estimateModal = ref<InstanceType<typeof EstimateModal>>();
    const confirmationModal = ref<InstanceType<typeof ConfirmationModal>>();
    const estimateCustomerToDelete = ref<string | null>(null);
    const estimateIdToDelete = ref<number | null>(null);
    const feedbackToast = ref<InstanceType<typeof Toast>>();

    /**
     * Opens the estimate modal in create mode
     */
    const openEstimateModalForCreation = (): void => {
      estimateModal.value?.open(ModalMode.CREATE);
    };

    /**
     * Opens the estimate modal in edit mode
     * @param estimate the estimate to be opened in the modal
     */
    const openEstimateModalForEdition = (estimate: Estimate): void => {
      estimateModal.value?.open(ModalMode.EDIT, estimate);
    };

    /**
     * Opens the confirmation modal for deletion
     * @param estimate the estimate to setted to deletion
     */
    const openConfirmationModalForDeletion = (estimate: Estimate): void => {
      estimateCustomerToDelete.value = estimate.customerName;
      estimateIdToDelete.value = estimate.id ?? null;
      confirmationModal.value?.open();
    };

    /**
     * Handles the user input in the query input field
     */
    const handleQueryInput = (): void => {
      updateEstimateData();
    };

    /**
     * Handles the creation of an estimate
     */
    const handleEstimateCreation = (): void => {
      updateEstimateData();
      feedbackToast.value?.open("Orçamento criado com sucesso");
    };

    /**
     * Handles the update of an estimate
     */
    const handleEstimateUpdate = (): void => {
      updateEstimateData();
      feedbackToast.value?.open("Orçamento atualizado com sucesso");
    };

    /**
     * Handles the deletion of an estimate
     */
    const handleEstimateDeletion = (): void => {
      if (estimateIdToDelete.value) {
        estimateService.delete(estimateIdToDelete.value).then(() => {
          updateEstimateData();
          feedbackToast.value?.open("Orçamento excluído com sucesso");
        });
      } else {
        throw new Error("Could not handle the estimate deletion");
      }
    };

    /**
     * Updates estimates data by fetching from the database
     */
    const updateEstimateData = (): void => {
      estimateService
        .searchByCustomerName(query.value)
        .then((result: Estimate[]) => {
          estimates.value = result;
        });
    };

    // Initialization
    updateEstimateData();

    return {
      query,
      estimates,
      estimateModal,
      openEstimateModalForCreation,
      openEstimateModalForEdition,
      openConfirmationModalForDeletion,
      confirmationModal,
      estimateCustomerToDelete,
      estimateIdToDelete,
      feedbackToast,
      handleEstimateDeletion,
      handleEstimateCreation,
      handleEstimateUpdate,
      handleQueryInput,
    };
  },
});
</script>
