import { dateToString } from "@/utils/util";
import { EstimateItem } from "../base/estimate-item.model";
import { Estimate } from "../base/estimate.model";
import { EstimateItemForm } from "./estimate-item.form";

/**
 * Class that represents an estimate form data
 */
export class EstimateForm {
  id?: number;
  customerName: string;
  customerAddress: string;
  customerPhone: string;
  date: string;
  items: EstimateItemForm[];

  constructor(estimate?: Estimate) {
    if (estimate) {
      this.id = estimate.id;
      this.customerName = estimate.customerName ?? '';
      this.customerAddress = estimate.customerAddress ?? '';
      this.customerPhone = estimate.customerPhone ?? '';
      this.date = dateToString(estimate.date);
      this.items = estimate.items.map((estimateItem: EstimateItem) => new EstimateItemForm(estimateItem));
    } else {
      this.customerName = '';
      this.customerAddress = '';
      this.customerPhone = '';
      this.date = dateToString(new Date());
      this.items = [];
    }
  }
}
