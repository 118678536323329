
import { computed, defineComponent, ref } from "vue";
import { Config } from "@/models/base/config.model";
import { ConfigService } from "@/services/config-service";
import { db } from "@/database/market-db";
import Header from "@/components/base/Header.vue";
import router from "@/router";

export default defineComponent({
  name: "FirstAccess",
  components: {
    Header,
  },
  setup() {
    const username = ref("");
    const storename = ref("");
    const configService = new ConfigService(db);

    /**
     * Checks if the user can continue by checking if username or storename are filled
     */
    const canContinue = computed(() => {
      return username.value && storename.value;
    });

    /**
     * Register the username and storename as configuration in the database
     */
    const onContinue = (): void => {
      const config = Config.fromNames(username.value, storename.value);
      configService.postOrPut(config).then(() => {
        router.push("/first-notice");
      });
    };

    return { username, storename, canContinue, onContinue };
  },
});
