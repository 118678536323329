import Dexie from 'dexie';
import { Product } from '../models/base/product.model';
import { Customer } from '@/models/base/customer.model';
import { Sale } from '@/models/base/sale.model';
import { Config } from '@/models/base/config.model';
import { Category } from './../models/base/category.model';
import { Estimate } from '@/models/base/estimate.model';

export class MarketDb extends Dexie {
  products: Dexie.Table<Product, number>;
  customers: Dexie.Table<Customer, number>;
  sales: Dexie.Table<Sale, number>;
  config: Dexie.Table<Config, number>;
  categories: Dexie.Table<Category, number>;
  estimates: Dexie.Table<Estimate, number>;

  /** Constructor */
  constructor() {
    // call super
    super("MarketDB");

    // database schema migrations
    this.version(1).stores({
      products: '++id, name, cost, inBulk, price1, price2, weight, category, provider'
    });

    this.version(2).stores({
      customers: '++id, name, address, phone'
    });

    this.version(3).stores({
      sales: '++id, value, type, date'
    });

    this.version(4).stores({
      config: 'id'
    });

    this.version(5).stores({
      categories: '++id, name'
    });

    this.version(6).stores({
      estimates: '++id, customerName, customerAddress, customerPhone, date, items'
    });

    this.version(7).stores({
      estimates: '++id, customerName, customerAddress, customerPhone, date'
    });

    // initializing state
    this.products = this.table("products");
    this.customers = this.table("customers");
    this.sales = this.table("sales");
    this.config = this.table("config");
    this.categories = this.table("categories");
    this.estimates = this.table("estimates");
  }
}

export const db = new MarketDb();