import { stringToDate } from "@/utils/util";
import { EstimateItem } from "./estimate-item.model";
import { EstimateForm } from "../forms/estimate.form";
import { EstimateItemForm } from "../forms/estimate-item.form";

/**
 * Class that represents an estimate
 */
export class Estimate {
  id?: number;
  customerName: string | null;
  customerAddress: string | null;
  customerPhone: string | null;
  date: Date | null;
  items: EstimateItem[];

  constructor(
    customerName: string | null,
    customerAddress: string | null,
    customerPhone: string | null,
    date: Date | null,
    items: EstimateItem[],
    id?: number
  ) {
    this.customerName = customerName;
    this.customerAddress = customerAddress;
    this.customerPhone = customerPhone;
    this.date = date;
    this.items = items;
    this.id = id;
  }

  static fromBlank(): Estimate {
    return new Estimate(
      null,
      null,
      null,
      null,
      []
    );
  }

  static fromForm(estimateForm: EstimateForm): Estimate {
    return new Estimate(
      estimateForm.customerName ?? null,
      estimateForm.customerAddress ?? null,
      estimateForm.customerPhone ?? null,
      stringToDate(estimateForm.date),
      estimateForm.items.map((estimateItemForm: EstimateItemForm) => EstimateItem.fromForm(estimateItemForm)),
      estimateForm.id
    )
  }
}
