<template>
  <!-- Navigation bar -->
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
    <!-- Container -->
    <div class="container-fluid">
      <!-- Brand -->
      <div class="navbar-brand ms-1">
        <i class="bi bi-signpost-2 me-2"></i>
        <span class="me-2">Market App</span>
      </div>

      <!-- Toggler -->
      <button
        v-if="!brandOnly"
        class="navbar-toggler"
        data-bs-toggle="collapse"
        data-bs-target="#navbarContent"
        aria-controls="navbarContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <!-- Navigation bar items -->
      <div
        v-if="!brandOnly"
        class="collapse navbar-collapse mt-3 mt-lg-0"
        id="navbarContent"
      >
        <!-- Navigations items -->
        <ul class="navbar-nav ms-1 ms-lg-0 me-auto mb-2 mb-lg-0">
          <li class="nav-item me-lg-2">
            <router-link class="nav-link px-2" to="/home">
              Página Inicial
            </router-link>
          </li>
          <li class="nav-item me-lg-2">
            <router-link class="nav-link px-2" to="/sales">
              Vendas
            </router-link>
          </li>
          <li class="nav-item me-lg-2">
            <router-link class="nav-link px-2" to="/products">
              Produtos
            </router-link>
          </li>
          <li class="nav-item me-lg-2">
            <router-link class="nav-link px-2" to="/customers">
              Clientes
            </router-link>
          </li>
          <li class="nav-item me-lg-2">
            <router-link class="nav-link px-2" to="/estimates">
              Orçamentos
            </router-link>
          </li>
        </ul>

        <!-- Navigation buttons -->
        <div class="d-flex flex-column flex-sm-row mb-2 mb-sm-2 mb-lg-0">
          <button
            class="btn btn-secondary mb-2 mb-sm-0 me-sm-2"
            @click="router.push('/config')"
          >
            Configurações
          </button>
          <button disabled class="btn btn-danger me-lg-1">
            Sair do Sistema
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import router from "@/router";

export default defineComponent({
  name: "Header",
  props: {
    brandOnly: { type: Boolean, default: false },
  },
  setup() {
    // expose template variables
    return { router };
  },
});
</script>

<style lang="scss">
@import "@/styles/global";

.router-link-active {
  border-radius: 0.25rem;
  background-color: $teal-700 !important;
}
</style>