
import { computed, defineComponent, ref } from "vue";
import { ModalMode } from "@/models/enums/modal-mode.enum";
import { Tab } from "@/models/base/tab.model";
import { Product } from "@/models/base/product.model";
import { Category } from "@/models/base/category.model";
import { ProductService } from "@/services/product-service";
import { CategoryService } from "@/services/category-service";
import { db } from "@/database/market-db";
import ProductTable from "@/components/tables/ProductTable.vue";
import ProductModal from "@/components/modals/ProductModal.vue";
import CategoryModal from "@/components/modals/CategoryModal.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import Toast from "@/components/base/Toast.vue";
import Tabs from "@/components/base/Tabs.vue";

export default defineComponent({
  name: "Products",
  components: {
    ProductTable,
    ProductModal,
    CategoryModal,
    ConfirmationModal,
    Toast,
    Tabs,
  },
  setup() {
    const categories = ref(new Array<Category>());
    const selectedTabId = ref(0);
    const query = ref("");
    const tabsComponent = ref<InstanceType<typeof Tabs>>();
    const products = ref(new Array<Product>());
    const productService = new ProductService(db);
    const categoryService = new CategoryService(db);
    const productModal = ref<InstanceType<typeof ProductModal>>();
    const categoryModal = ref<InstanceType<typeof CategoryModal>>();
    const confirmationModal = ref<InstanceType<typeof ConfirmationModal>>();
    const productToDelete = ref<string | null>(null);
    const productIdToDelete = ref<number | null>(null);
    const feedbackToast = ref<InstanceType<typeof Toast>>();

    /**
     * Evaluate the tabs of the products view based on the
     * existing registered categories
     */
    const tabs = computed(() => {
      const fetchedTabs = [new Tab("Todos os Produtos", 0)];

      categories.value
        .map((category: Category) => Tab.fromCategory(category))
        .forEach((tab: Tab) => fetchedTabs.push(tab));

      return fetchedTabs;
    });

    /**
     * Opens the product modal in create mode
     */
    const openProductModalForCreation = (): void => {
      productModal.value?.open(ModalMode.CREATE);
    };

    /**
     * Opens the product modal in edit mode
     * @param product the product to be opened in the modal
     */
    const openProductModalForEdition = (product: Product): void => {
      productModal.value?.open(ModalMode.EDIT, product);
    };

    /**
     * Opens the confirmation modal for deletion
     * @param product the product to setted to deletion
     */
    const openConfirmationModalForDeletion = (product: Product): void => {
      productToDelete.value = product.name;
      productIdToDelete.value = product.id ?? null;
      confirmationModal.value?.open();
    };

    /**
     * Opens the category modal
     */
    const openCategoryModal = (): void => {
      categoryModal.value?.open();
    };

    /**
     * Handles the user input in the query input field
     */
    const handleQueryInput = (): void => {
      updateProductData();
    };

    /**
     * Handles the creation of a product
     */
    const handleProductCreation = (): void => {
      updateProductData();
      feedbackToast.value?.open("Produto criado com sucesso");
    };

    /**
     * Handles the update of a product
     */
    const handleProductUpdate = (): void => {
      updateProductData();
      feedbackToast.value?.open("Produto atualizado com sucesso");
    };

    /**
     * Handles the deletion of a product
     */
    const handleProductDeletion = (): void => {
      if (productIdToDelete.value) {
        productService.delete(productIdToDelete.value).then(() => {
          updateProductData();
          feedbackToast.value?.open("Produto excluído com sucesso");
        });
      } else {
        throw new Error("Could not handle the product deletion");
      }
    };

    /**
     * Handles the updated event emitted by the category modal
     * by calling the method to update category data and tabs
     */
    const handleCategoryUpdate = (): void => {
      updateCategoryData();
    };

    /**
     * Handles the click on an item in the tab list
     */
    const handleTabItemClick = (id: number): void => {
      selectedTabId.value = id;
      updateProductData();
    };

    /**
     * Updates product data by fetching from the database
     */
    const updateProductData = (): void => {
      const category =
        selectedTabId.value > 0
          ? tabs.value.find((tab: Tab) => tab.id === selectedTabId.value)?.name
          : null;

      productService
        .searchByNameAndCategory(query.value, category)
        .then((result: Product[]) => {
          products.value = result;
        });
    };

    /**
     * Update category data by fetching from the database
     */
    const updateCategoryData = (): void => {
      categoryService.getAll().then((result: Category[]) => {
        categories.value = result;
      });
    };

    // Initialization
    updateProductData();
    updateCategoryData();

    return {
      tabs,
      selectedTabId,
      query,
      products,
      productModal,
      categoryModal,
      openProductModalForCreation,
      openProductModalForEdition,
      openCategoryModal,
      openConfirmationModalForDeletion,
      confirmationModal,
      productToDelete,
      productIdToDelete,
      handleProductDeletion,
      feedbackToast,
      handleProductCreation,
      handleProductUpdate,
      handleCategoryUpdate,
      handleQueryInput,
      handleTabItemClick,
      tabsComponent,
    };
  },
});
