<template>
  <!-- New Customer Modal -->
  <div
    class="modal fade"
    :id="modalId"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    @keydown.enter="onConfirmation()"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <!-- Modal header -->
        <div class="modal-header bg-light">
          <h5 class="modal-title">{{ title }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
          ></button>
        </div>

        <!-- Modal body -->
        <div class="modal-body">
          <div :class="{ 'was-validated': wasValidated }" class="mb-3">
            <label for="customerName" class="form-label">Nome do cliente</label>
            <div class="input-group">
              <span class="input-group-text">@</span>
              <input
                class="form-control rounded-end"
                id="customerName"
                placeholder="Digite o nome do cliente"
                v-model="customer.name"
                required
              />
              <div class="invalid-feedback">Insira um nome válido</div>
            </div>
          </div>

          <div class="mb-3">
            <label for="customerAddress" class="form-label">Endereço</label>
            <input
              class="form-control"
              id="customerAddress"
              placeholder="Digite o endereço do cliente"
              v-model="customer.address"
            />
          </div>

          <div class="mb-3">
            <label for="customerPhone" class="form-label">Telefone</label>
            <input
              class="form-control"
              id="customerPhone"
              placeholder="Digite o telefone do cliente"
              v-model="customer.phone"
            />
          </div>
        </div>

        <!-- Modal footer -->
        <div class="modal-footer bg-light">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Cancelar
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="onConfirmation()"
          >
            {{ confirmationLabel }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, onUnmounted, ref } from "vue";
import { ModalMode } from "@/models/enums/modal-mode.enum";
import { Customer } from "@/models/base/customer.model";
import { Modal } from "bootstrap";
import { CustomerForm } from "@/models/forms/customer.form";
import { CustomerService } from "@/services/customer-service";
import { db } from "@/database/market-db";

export default defineComponent({
  name: "CustomerModal",
  props: {
    modalId: { type: String, required: true },
  },
  emits: ["created", "updated"],
  setup(props, context) {
    const mode = ref(ModalMode.CREATE);
    const customer = ref(new CustomerForm());
    const customerService = new CustomerService(db);
    const wasValidated = ref(false);
    let customerModal: Modal | null;

    onMounted(() => {
      const modalElement = document.getElementById(props.modalId);
      const inputElement = document.getElementById("customerName");
      customerModal = modalElement ? new Modal(modalElement) : null;
      modalElement?.addEventListener("shown.bs.modal", () =>
        inputElement?.focus()
      );
    });

    onUnmounted(() => {
      if (customerModal) {
        customerModal.dispose();
      }
    });

    /**
     * Computes the title of the modal
     */
    const title = computed(() => {
      if (mode.value === ModalMode.EDIT) return "Editar Cliente";
      if (mode.value === ModalMode.CREATE) return "Cadastrar Cliente";
      throw new Error("Could not get the title from the specified modal mode");
    });

    /**
     * Computes the label of the confirmation button of the modal
     */
    const confirmationLabel = computed(() => {
      if (mode.value === ModalMode.EDIT) return "Atualizar";
      if (mode.value === ModalMode.CREATE) return "Cadastrar";
      throw new Error(
        "Could not get the confirmation label from the specified modal mode"
      );
    });

    /**
     * Open the modal in a specific mode and with specific data
     * @param openMode the mode to open the modal
     * @param openCustomer the customer to load in the modal
     */
    const open = (openMode: ModalMode, openCustomer?: Customer): void => {
      mode.value = openMode;
      customer.value = new CustomerForm(openCustomer);

      if (customerModal) {
        customerModal.show();
      } else {
        throw new Error("Could not find the modal to open");
      }
    };

    /**
     * Close the modal
     */
    const close = (): void => {
      if (customerModal) {
        customerModal.hide();
      } else {
        throw new Error("Could not find the modal to close");
      }
    };

    /**
     * Handle the click in the confirmation button
     */
    const onConfirmation = (): void => {
      // form validation
      if (!validate()) return;

      // on confirmation, if valid
      const customerToPut = new Customer(customer.value);
      customerService.postOrPut(customerToPut).then(() => {
        switch (mode.value) {
          case ModalMode.CREATE:
            context.emit("created");
            break;
          case ModalMode.EDIT:
            context.emit("updated");
            break;
          default:
            throw new Error("Unknown mode for the customer modal");
        }

        close();
      });
    };

    /**
     * Validate the user input before submitting
     */
    const validate = (): boolean => {
      const customerNameInput = document.getElementById(
        "customerName"
      ) as HTMLInputElement;

      if (!customerNameInput.validity.valid) {
        customerNameInput.focus();
        wasValidated.value = true;
        return false;
      }

      wasValidated.value = false;
      return true;
    };

    return {
      title,
      confirmationLabel,
      open,
      close,
      customer,
      onConfirmation,
      wasValidated,
    };
  },
});
</script>
