import { Sale } from "../base/sale.model";
import { SaleType } from "../enums/sale-type.enum";
import { dateToString, numberToMoney, typeToString } from './../../utils/util';

/**
 * Class that represents a sale form data
 */
export class SaleForm {
  id?: number;
  value: string;
  type: string;
  date: string;

  constructor(
    value: string,
    type: string,
    date: string,
    id?: number
  ) {
    this.value = value;
    this.type = type;
    this.date = date;
    this.id = id;
  }

  static fromBlank(): SaleForm {
    return new SaleForm('', '', '');
  }

  static fromDefaults(): SaleForm {
    return new SaleForm('', SaleType.CASH, dateToString(new Date()));
  }

  static fromObj(sale: Sale): SaleForm {
    return new SaleForm(
      numberToMoney(sale.value, "R$ "),
      typeToString<SaleType>(sale.type),
      dateToString(sale.date),
      sale.id
    );
  }

  static fromObjWithPreview(sale: Sale): SaleForm {
    return new SaleForm(
      sale.value ? numberToMoney(sale.value, "R$ ") : "R$ -",
      sale.type ? typeToString<SaleType>(sale.type) : SaleType.CASH,
      sale.date ? dateToString(sale.date) : dateToString(new Date())
    );
  }
}
