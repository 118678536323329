import { BaseService } from './base/base-service';
import { MarketDb } from "@/database/market-db";
import { Estimate } from '@/models/base/estimate.model';

/**
 * Class that represents a collection of methods related to estimate operations
 */
export class EstimateService extends BaseService {
  /**
   * Constructor
   */
  constructor(db: MarketDb) {
    // call super
    super(db);
  }

  /**
   * Gets all estimates from the database
   * @returns a promise that resolves to a list of all estimates
   */
  public async getAll(): Promise<Estimate[]> {
    return this.db.estimates.toArray();
  }

  /**
   * Posts or Puts a new estimate into the database
   * @param estimate the estimate to be posted
   * @returns a promise that resolves to the generated or updated ID
   */
  public async postOrPut(estimate: Estimate): Promise<number> {
    return this.db.estimates.put(estimate);
  }

  /**
   * 
   * @param id the id of the estimate to be deleted
   * @returns returns an empty promise
   */
  public async delete(id: number): Promise<void> {
    return this.db.estimates.delete(id);
  }

  /**
   * Searches the estimate by customer name
   * @param customerName the name of the customer to be searched in the available estimates
   * @returns a promise that resolves to a list of estimates filtered by customer name
   */
  public async searchByCustomerName(customerName: string): Promise<Estimate[]> {
    return this.db.estimates.filter((estimate: Estimate) =>
      estimate.customerName != null && estimate.customerName.toLowerCase().includes(customerName.toLowerCase())).toArray();
  }
}
