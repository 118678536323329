<template>
  <div class="flex-fill" id="tabsContainer">
    <div id="tabsGroup">
      <!-- This component has native behavior -->
      <!-- Components will be rendered here -->
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  onUnmounted,
  PropType,
  ref,
  watch,
} from "vue";
import { Tab } from "@/models/base/tab.model";

export default defineComponent({
  name: "Tabs",
  props: {
    selectedTabId: { type: Number, required: true },
    tabs: { type: Object as PropType<Tab[]>, default: new Array<Tab>() },
  },
  emits: ["itemClicked"],
  setup(props, context) {
    const containerBias = -61;
    const containerWidth = ref(0);

    /**
     * After mounting, register resize events to render the tabs when size changes
     */
    onMounted(() => {
      window.addEventListener("resize", renderTabs);
    });

    /**
     * If the element is not present anymore, remove the resize event listener
     */
    onUnmounted(() => {
      window.removeEventListener("resize", renderTabs);
    });

    /**
     * Watch the tabs and trigger rendering when necessary
     */
    watch(
      () => props.tabs,
      () => renderTabs()
    );

    /**
     * Watch the selected tab ID and trigger the function to highlight
     * the selected tab by using bootstrap classes
     */
    watch(
      () => props.selectedTabId,
      () => updateSelectedTab()
    );

    /**
     * Render the tabs by adding them only until they fit the container width
     */
    const renderTabs = (): void => {
      const tabsContainer = document.getElementById("tabsContainer");
      const tabsGroup = document.getElementById("tabsGroup");

      if (tabsContainer && tabsGroup) {
        containerWidth.value = tabsContainer.scrollWidth;
        removeAllTabs(tabsGroup);

        let totalWidth = 0;

        for (const tab of props.tabs) {
          totalWidth += addTab(tab, tabsGroup);
          if (totalWidth > containerWidth.value + containerBias) {
            removeLastTab(tabsGroup);
            addExtensionTab(tabsGroup);
            break;
          }
        }
      }
    };

    /**
     * Adds a tab with its correct class definitions to the provided element
     * @param tab the tab to be added to the element
     * @param element the element where to add the tab
     * @returns the width of the added element
     */
    const addTab = (tab: Tab, element: Element): number => {
      const span = document.createElement("span");
      span.textContent = tab.name;

      const child = document.createElement("button");
      child.appendChild(span);

      child.classList.add("btn");
      child.classList.add("me-2");

      if (tab.id === props.selectedTabId) {
        child.classList.add("btn-secondary");
      } else {
        child.classList.add("btn-outline-secondary");
      }

      child.addEventListener("click", () => {
        handleItemClick(tab.id);
      });

      element.appendChild(child);
      return child.offsetWidth + 8;
    };

    /**
     * Removes the last tab from the provided element
     * @param element the element from where to remove the child
     */
    const removeLastTab = (element: Element): void => {
      element.removeChild(element.children[element.children.length - 1]);
    };

    /**
     * Removes all tabs from the provided element
     * @param element the element from where to remove the tabs
     */
    const removeAllTabs = (element: Element): void => {
      while (element.firstChild) {
        element.removeChild(element.firstChild);
      }
    };

    /**
     * Adds an extension tab that can be binded to extra functionality
     * @param element the element where to add the extension tab
     */
    const addExtensionTab = (element: Element): void => {
      const i = document.createElement("i");
      i.classList.add("bi");
      i.classList.add("bi-caret-right");

      const child = document.createElement("button");
      child.appendChild(i);

      child.classList.add("btn");
      child.classList.add("me-2");
      child.classList.add("btn-outline-secondary");

      element.appendChild(child);
    };

    /**
     * Updates the classes of the selected items
     * @param id the id of the tab element to be considered selected
     */
    const updateSelectedTab = (): void => {
      const index = props.tabs.findIndex(
        (tab: Tab) => tab.id === props.selectedTabId
      );
      const tabsGroup = document.getElementById("tabsGroup");

      if (tabsGroup && index >= 0) {
        let currentItem = 0;
        for (const child of tabsGroup.children) {
          if (currentItem === index) {
            child.classList.remove("btn-outline-secondary");
            child.classList.add("btn-secondary");
          } else {
            child.classList.remove("btn-secondary");
            child.classList.add("btn-outline-secondary");
          }
          currentItem++;
        }
      }
    };

    /**
     * Emits an event when an item is clicked
     * @param id the id of the clicked item
     */
    const handleItemClick = (id: number): void => {
      context.emit("itemClicked", id);
    };
  },
});
</script>
