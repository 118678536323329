import { Product } from "../base/product.model";
import { numberToMoney, numberToString } from './../../utils/util';

/**
 * Class that represents a product form data
 */
export class ProductForm {
  id?: number;
  name: string;
  cost: string;
  inBulk: string;
  price1: string;
  price2: string;
  weight: string;
  category: string;
  provider: string;

  constructor(product?: Product) {
    if (product) {
      this.id = product.id;
      this.name = product.name;
      this.cost = numberToMoney(product.cost);
      this.inBulk = numberToMoney(product.inBulk)
      this.price1 = numberToMoney(product.price1)
      this.price2 = numberToMoney(product.price2)
      this.weight = numberToString(product.weight)
      this.category = product.category;
      this.provider = product.provider;
    } else {
      this.name = '';
      this.cost = '';
      this.inBulk = '';
      this.price1 = '';
      this.price2 = '';
      this.weight = '';
      this.category = '';
      this.provider = '';
    }
  }
}
