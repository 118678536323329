<template>
  <main
    class="
      container-fluid
      p-3
      mt-header
      d-flex
      flex-column
      align-items-center
      h-100
    "
  >
    <!-- Header -->
    <Header brandOnly />

    <!-- Form Section -->
    <div class="mt-2 mt-sm-3 mt-md-4 mt-lg-5 mw-35-rem">
      <!-- First Access Section -->
      <section class="mb-4">
        <h1 class="mb-2">
          <i class="bi bi-shop"></i>
        </h1>
        <h5>Bem vindo(a) ao Market Web App!</h5>
        <h6>
          Para começar, informe alguns de seus dados para personalizar sua
          aplicação.
        </h6>
      </section>

      <!-- Personal data section -->
      <section class="mb-4">
        <h4>Dados Pessoais</h4>
        <div class="mb-3">
          <label for="username" class="form-label">Seu nome de usuário</label>
          <div class="input-group">
            <span class="input-group-text">@</span>
            <input
              v-focus
              class="form-control"
              id="username"
              placeholder="Digite seu nome de usuário"
              v-model="username"
            />
          </div>
        </div>
      </section>

      <!-- Store data section -->
      <section class="mb-4">
        <h4>Dados do Estabelecimento</h4>
        <div class="mb-3">
          <label for="storename" class="form-label"
            >Nome do estabelecimento</label
          >
          <div class="input-group">
            <span class="input-group-text">@</span>
            <input
              class="form-control"
              id="storename"
              placeholder="Digite o nome do estabelecimento"
              v-model="storename"
            />
          </div>
        </div>
      </section>

      <!-- Confirmation button -->
      <section>
        <button
          :disabled="!canContinue"
          class="btn btn-primary"
          @click="onContinue()"
        >
          Começar
        </button>
      </section>
    </div>
  </main>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { Config } from "@/models/base/config.model";
import { ConfigService } from "@/services/config-service";
import { db } from "@/database/market-db";
import Header from "@/components/base/Header.vue";
import router from "@/router";

export default defineComponent({
  name: "FirstAccess",
  components: {
    Header,
  },
  setup() {
    const username = ref("");
    const storename = ref("");
    const configService = new ConfigService(db);

    /**
     * Checks if the user can continue by checking if username or storename are filled
     */
    const canContinue = computed(() => {
      return username.value && storename.value;
    });

    /**
     * Register the username and storename as configuration in the database
     */
    const onContinue = (): void => {
      const config = Config.fromNames(username.value, storename.value);
      configService.postOrPut(config).then(() => {
        router.push("/first-notice");
      });
    };

    return { username, storename, canContinue, onContinue };
  },
});
</script>

<style lang="scss">
.mw-35-rem {
  max-width: 35rem;
}
</style>
