import { ProductForm } from "../forms/product.form";
import { moneyToNumber, stringToNumber } from "@/utils/util";
import { SelectableItem } from "./selectable-item.interface";

/**
 * Class that represents a product
 */
export class Product implements SelectableItem {
  id?: number;
  name: string;
  cost: number | null;
  inBulk: number | null;
  price1: number | null;
  price2: number | null;
  weight: number | null;
  category: string;
  provider: string;

  constructor(productForm: ProductForm) {
    this.id = productForm.id;
    this.name = productForm.name;
    this.cost = moneyToNumber(productForm.cost);
    this.inBulk = moneyToNumber(productForm.inBulk);
    this.price1 = moneyToNumber(productForm.price1);
    this.price2 = moneyToNumber(productForm.price2);
    this.weight = stringToNumber(productForm.weight);
    this.category = productForm.category;
    this.provider = productForm.provider;
  }
}
