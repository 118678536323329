import { BaseService } from './base/base-service';
import { MarketDb } from "@/database/market-db";
import { Product } from '../models/base/product.model';

/**
 * Class that represents a collection of methods related to product operations
 */
export class ProductService extends BaseService {
  /**
   * Constructor
   */
  constructor(db: MarketDb) {
    // call super
    super(db);
  }

  /**
   * Gets all products from the database
   * @returns a promise that resolves to a list of all products
   */
  public async getAll(): Promise<Product[]> {
    return this.db.products.toArray();
  }

  /**
   * Posts or Puts a new product into the database
   * @param product the product to be posted
   * @returns a promise that resolves to the generated or updated ID
   */
  public async postOrPut(product: Product): Promise<number> {
    return this.db.products.put(product);
  }

  /**
   * 
   * @param id the id of the product to be deleted
   * @returns returns an empty promise
   */
  public async delete(id: number): Promise<void> {
    return this.db.products.delete(id);
  }

  /**
   * Searches the products by name and an optional category
   * @param name the name of the product to be searched
   * @param category the name of an optional category to be searched
   * @returns a promise that resolves to a list of products filtered by name
   */
  public async searchByNameAndCategory(name: string, categoryName?: string | null): Promise<Product[]> {
    return this.db.products.filter((product: Product) => {
      const hasName = product.name.toLowerCase().includes(name.toLowerCase());

      if (categoryName) {
        const hasCategory = product.category === categoryName;
        return hasName && hasCategory;
      }

      return hasName;
    }).toArray();
  }
}