<template>
  <table class="table table-sm table-hover">
    <!-- Table header -->
    <thead>
      <tr>
        <th scope="col" class="px-2 text-nowrap sticky-top bg-white">Cód.</th>
        <th scope="col" class="px-2 text-nowrap sticky-top bg-white">Data</th>
        <th scope="col" class="px-2 text-nowrap sticky-top bg-white">
          Nome do cliente
        </th>
        <th scope="col" class="px-2 text-nowrap sticky-top bg-white">
          Endereço do cliente
        </th>
        <th scope="col" class="px-2 text-nowrap sticky-top bg-white">
          Telefone do cliente
        </th>
        <th scope="col" class="px-2 text-nowrap sticky-top bg-white">
          Valor Total
        </th>
        <th scope="col" class="px-2 text-nowrap sticky-top bg-white">Ações</th>
        <th scope="col" class="px-2 text-nowrap sticky-top bg-white">
          Relatórios
        </th>
      </tr>
    </thead>

    <!-- Table body -->
    <tbody>
      <tr v-for="estimate in estimates" :key="estimate.id">
        <td class="px-2 text-nowrap">
          {{ estimate.id }}
        </td>
        <td class="px-2 text-nowrap">
          {{ dateToString(estimate.date) }}
        </td>
        <td class="px-2">
          {{ estimate.customerName }}
        </td>
        <td class="px-2">
          {{ estimate.customerAddress }}
        </td>
        <td class="px-2">
          {{ estimate.customerPhone }}
        </td>
        <td class="px-2">
          {{ numberToMoney(estimateTotal(estimate.items), "R$ ") }}
        </td>
        <td class="px-2 text-nowrap">
          <a class="cursor-pointer text-dark" @click="onEdit(estimate)">
            <i class="bi bi-pencil-square me-2"></i>
          </a>
          <a class="cursor-pointer text-dark" @click="onDelete(estimate)">
            <i class="bi bi-trash"></i>
          </a>
        </td>
        <td class="px-2">
          <a href="#" class="bnt-link" @click="generateEstimatePdf(estimate)">
            <i class="bi bi-printer me-2"></i>
            <span>Gerar Orçamento</span>
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Estimate } from "@/models/base/estimate.model";
import { EstimateItem } from "@/models/base/estimate-item.model";
import { dateToString, numberToMoney } from "@/utils/util";
import { generateEstimateReport } from "@/templates/estimate-report";

export default defineComponent({
  name: "EstimateTable",
  props: {
    estimates: {
      type: Object as PropType<Estimate[]>,
      default: new Array<Estimate>(),
    },
  },
  emits: ["onEdit", "onDelete"],
  setup(props, context) {
    /**
     * Computes the total associated with the items of an estimate
     * @param estimateItems the items in the estimate
     */
    const estimateTotal = (estimateItems: EstimateItem[]): number => {
      return estimateItems
        .map(
          (estimateItem: EstimateItem) =>
            estimateItem.quantity * estimateItem.price
        )
        .reduce((previous, current) => previous + current, 0);
    };

    /**
     * Emits an event when the user clicks in the edit action
     * @param estimate the estimate associated with the edit action
     */
    const onEdit = (estimate: Estimate): void => {
      context.emit("onEdit", estimate);
    };

    /**
     * Emits an event when the user clicks in the delete action
     * @param estimate the estimate associated with the delete action
     */
    const onDelete = (estimate: Estimate): void => {
      context.emit("onDelete", estimate);
    };

    /**
     * Generates a PDF with the estimate data
     * @param estimate the estimate to be used when generating the PDF
     */
    const generateEstimatePdf = (estimate: Estimate): void => {
      generateEstimateReport(estimate);
    };

    // expose template variables
    return {
      onEdit,
      onDelete,
      dateToString,
      numberToMoney,
      estimateTotal,
      generateEstimatePdf,
    };
  },
});
</script>
