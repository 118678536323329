<template>
  <table class="table table-sm table-hover">
    <!-- Table header -->
    <thead>
      <tr>
        <th scope="col" class="px-2 text-nowrap sticky-top bg-white">Cód.</th>
        <th scope="col" class="px-2 text-nowrap sticky-top bg-white">
          Nome ou Descrição do Produto
        </th>
        <th scope="col" class="px-2 text-nowrap sticky-top bg-white">
          Quantidade
        </th>
        <th scope="col" class="px-2 text-nowrap sticky-top bg-white">
          Preço Unitário
        </th>
        <th scope="col" class="px-2 text-nowrap sticky-top bg-white">
          Preço Total
        </th>
        <th scope="col" class="px-2 text-nowrap sticky-top bg-white">Ações</th>
      </tr>
    </thead>

    <!-- Table body -->
    <tbody>
      <tr
        v-for="(estimateItem, estimateItemIndex) in estimateItems"
        :key="estimateItem.id"
        class="align-middle"
      >
        <td class="px-2 text-nowrap">
          {{ estimateItem.id }}
        </td>
        <td class="px-2">
          <input
            class="form-control form-control-sm"
            v-model="estimateItem.name"
            autocomplete="off"
          />
        </td>
        <td class="px-2">
          <span>
            <input
              class="form-control form-control-sm d-inline"
              style="width: 5rem"
              type="number"
              min="0"
              v-model="estimateItem.quantity"
              autocomplete="off"
            />
          </span>
        </td>
        <td class="px-2">
          <input
            class="form-control form-control-sm"
            style="width: 7rem"
            v-model="estimateItem.price"
            autocomplete="off"
          />
        </td>
        <td class="px-2">
          {{ evaluateTotal(estimateItem.quantity, estimateItem.price) }}
        </td>
        <td class="px-2 text-nowrap">
          <div
            class="cursor-pointer text-dark"
            @click="onDelete(estimateItemIndex)"
          >
            <i class="bi bi-trash"></i>
            Remover
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import {
  dateToString,
  moneyToNumber,
  numberToMoney,
  stringToNumber,
} from "@/utils/util";
import { EstimateItemForm } from "@/models/forms/estimate-item.form";

export default defineComponent({
  name: "EstimateItemsTable",
  props: {
    estimateItems: {
      type: Object as PropType<EstimateItemForm[]>,
      default: new Array<EstimateItemForm>(),
    },
  },
  emits: ["onDelete"],
  setup(props, context) {
    /**
     * Evaluates the total price of a product based on setted quantity and unit price
     * @param quantity a string representing the quantity of a product
     * @param unitPrice a string representing the price of a single unit of the product
     * @returns a string representing the total price for a product
     */
    const evaluateTotal = (quantity: string, unitPrice: string): string => {
      const quantityNumber = stringToNumber(quantity);
      const unitPriceNumber = moneyToNumber(unitPrice);

      if (quantityNumber != null && unitPriceNumber != null) {
        return numberToMoney(quantityNumber * unitPriceNumber, "R$ ");
      }

      return "R$ 0,00";
    };

    /**
     * Emits an event when the user clicks in the delete action
     * @param estimateItemIndex the index of the estimate item associated with the delete action
     */
    const onDelete = (estimateItemIndex: number): void => {
      context.emit("onDelete", estimateItemIndex);
    };

    // expose template variables
    return { onDelete, dateToString, numberToMoney, evaluateTotal };
  },
});
</script>
