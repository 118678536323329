<template>
  <main class="d-flex flex-column flex-fill overflow-hidden">
    <!-- Title section -->
    <section class="px-3 py-3 d-flex">
      <!-- Add input button -->
      <button
        class="btn btn-primary me-3 text-nowrap"
        @click="openCustomerModalForCreation()"
      >
        <i class="bi bi-plus-circle me-lg-2"></i>
        <span class="d-none d-lg-inline">Inserir Cliente</span>
      </button>

      <!-- Input value -->
      <div class="input-group">
        <span class="input-group-text" id="value-input">
          <span>@</span>
          <span class="d-none d-lg-inline">Pesquisar</span>
        </span>
        <input
          type="text"
          class="form-control"
          placeholder="Digite um termo de pesquisa"
          aria-label="value-input"
          aria-describedby="value-input"
          v-model="query"
          v-focus
          @input="handleQueryInput()"
        />
        <button class="btn btn-primary">
          <i class="bi bi-search me-lg-2"></i>
          <span class="d-none d-lg-inline">Pesquisar</span>
        </button>
      </div>
    </section>

    <!-- Tabular data -->
    <section class="px-3 pb-2 d-flex flex-column flex-fill overflow-auto">
      <CustomerTable
        :customers="customers"
        @onEdit="openCustomerModalForEdition($event)"
        @onDelete="openConfirmationModalForDeletion($event)"
      />
    </section>

    <!-- Modal to create or edit customers -->
    <CustomerModal
      ref="customerModal"
      :modalId="'customerModal'"
      @created="handleCustomerCreation()"
      @updated="handleCustomerUpdate()"
    />

    <!-- Modal to delete customers -->
    <ConfirmationModal
      ref="confirmationModal"
      :modalId="'confirmationModal'"
      :title="'Excluir cliente'"
      :confirmationLabel="'Confirmar'"
      @confirmed="handleCustomerDeletion()"
    >
      <div>
        <div>
          O cliente <strong>{{ customerToDelete }}</strong> será excluído.
        </div>
        <div>Esta ação é irreversível. Deseja continuar?</div>
      </div>
    </ConfirmationModal>

    <!-- Toast that handles feedback from operations -->
    <Toast ref="feedbackToast" :toastId="'toast'" />
  </main>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { ModalMode } from "@/models/enums/modal-mode.enum";
import { Customer } from "@/models/base/customer.model";
import { CustomerService } from "@/services/customer-service";
import { db } from "@/database/market-db";
import CustomerTable from "@/components/tables/CustomerTable.vue";
import CustomerModal from "@/components/modals/CustomerModal.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import Toast from "@/components/base/Toast.vue";

export default defineComponent({
  name: "Customers",
  components: {
    CustomerTable,
    CustomerModal,
    ConfirmationModal,
    Toast,
  },
  setup() {
    const query = ref("");
    const customers = ref(new Array<Customer>());
    const customerService = new CustomerService(db);
    const customerModal = ref<InstanceType<typeof CustomerModal>>();
    const confirmationModal = ref<InstanceType<typeof ConfirmationModal>>();
    const customerToDelete = ref<string | null>(null);
    const customerIdToDelete = ref<number | null>(null);
    const feedbackToast = ref<InstanceType<typeof Toast>>();

    /**
     * Opens the customer modal in create mode
     */
    const openCustomerModalForCreation = (): void => {
      customerModal.value?.open(ModalMode.CREATE);
    };

    /**
     * Opens the customer modal in edit mode
     * @param customer the customer to be opened in the modal
     */
    const openCustomerModalForEdition = (customer: Customer): void => {
      customerModal.value?.open(ModalMode.EDIT, customer);
    };

    /**
     * Opens the confirmation modal for deletion
     * @param customer the customer to setted to deletion
     */
    const openConfirmationModalForDeletion = (customer: Customer): void => {
      customerToDelete.value = customer.name;
      customerIdToDelete.value = customer.id ?? null;
      confirmationModal.value?.open();
    };

    /**
     * Handles the user input in the query input field
     */
    const handleQueryInput = (): void => {
      updateCustomerData();
    };

    /**
     * Handles the creation of a customer
     */
    const handleCustomerCreation = (): void => {
      updateCustomerData();
      feedbackToast.value?.open("Cliente criado com sucesso");
    };

    /**
     * Handles the update of a customer
     */
    const handleCustomerUpdate = (): void => {
      updateCustomerData();
      feedbackToast.value?.open("Cliente atualizado com sucesso");
    };

    /**
     * Handles the deletion of a customer
     */
    const handleCustomerDeletion = (): void => {
      if (customerIdToDelete.value) {
        customerService.delete(customerIdToDelete.value).then(() => {
          updateCustomerData();
          feedbackToast.value?.open("Cliente excluído com sucesso");
        });
      } else {
        throw new Error("Could not handle the customer deletion");
      }
    };

    /**
     * Updates customer data by fetching from the database
     */
    const updateCustomerData = (): void => {
      customerService.searchByName(query.value).then((result: Customer[]) => {
        customers.value = result;
      });
    };

    // Initialization
    updateCustomerData();

    return {
      query,
      customers,
      customerModal,
      openCustomerModalForCreation,
      openCustomerModalForEdition,
      openConfirmationModalForDeletion,
      confirmationModal,
      customerToDelete,
      customerIdToDelete,
      handleCustomerDeletion,
      feedbackToast,
      handleCustomerCreation,
      handleCustomerUpdate,
      handleQueryInput,
    };
  },
});
</script>
