import { SaleType } from "../enums/sale-type.enum";
import { SaleForm } from "../forms/sale.form";
import { extractFirstText, moneyToNumber, searchInEnum, stringToDate, stringToNumber, stringToType } from "@/utils/util";

/**
 * Class that represents a sale
 */
export class Sale {
  id?: number;
  value: number | null;
  type: SaleType | null;
  date: Date | null;

  constructor(
    value: number | null,
    type: SaleType | null,
    date: Date | null,
    id?: number
  ) {
    this.value = value;
    this.type = type;
    this.date = date;
    this.id = id;
  }

  static fromBlank(): Sale {
    return new Sale(null, null, null);
  }

  static fromObj(saleForm: SaleForm): Sale {
    return new Sale(
      moneyToNumber(saleForm.value),
      stringToType<SaleType>(saleForm.type),
      stringToDate(saleForm.date),
      saleForm.id
    );
  }

  static fromSaleInput(input: string): Sale {
    const value = stringToNumber(input);
    const text = extractFirstText(input);
    const type = text ? searchInEnum<SaleType>(text, SaleType) : null;
    const date = stringToDate(input);

    return new Sale(value, type, date);
  }
}
