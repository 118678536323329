<template>
  <!-- New Product Modal -->
  <div
    class="modal fade"
    :id="modalId"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    @keydown.enter="close()"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <!-- Modal header -->
        <div class="modal-header bg-light">
          <h5 class="modal-title">{{ title }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
          ></button>
        </div>

        <!-- Modal body -->
        <div class="modal-body">
          <slot></slot>
        </div>

        <!-- Modal footer -->
        <div class="modal-footer bg-light">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Cancelar
          </button>
          <button
            type="button"
            class="btn btn-danger"
            @click="onConfirmation()"
          >
            {{ confirmationLabel }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted } from "vue";
import { Modal } from "bootstrap";

export default defineComponent({
  name: "ConfirmationModal",
  props: {
    modalId: { type: String, required: true },
    title: { type: String, required: true },
    confirmationLabel: { type: String, required: true },
  },
  emits: ["confirmed"],
  setup(props, context) {
    let confirmationModal: Modal | null;

    onMounted(() => {
      const modalElement = document.getElementById(props.modalId);
      confirmationModal = modalElement ? new Modal(modalElement) : null;
    });

    onUnmounted(() => {
      if (confirmationModal) {
        confirmationModal.dispose();
      }
    });

    /**
     * Open the confirmation modal
     */
    const open = (): void => {
      if (confirmationModal) {
        confirmationModal.show();
      } else {
        throw new Error("Could not find the confirmation modal to open");
      }
    };

    /**
     * Close the confirmation modal
     */
    const close = (): void => {
      if (confirmationModal) {
        confirmationModal.hide();
      } else {
        throw new Error("Could not find the confirmation modal to close");
      }
    };

    /**
     * Handle the click in the confirmation button
     */
    const onConfirmation = (): void => {
      context.emit("confirmed");
      close();
    };

    // expose template variables
    return { open, close, onConfirmation };
  },
});
</script>
