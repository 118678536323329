import { moneyToNumber, stringToNumber } from "@/utils/util";
import { EstimateItemForm } from "../forms/estimate-item.form";

/**
 * Class that represents an estimate item
 */
export class EstimateItem {
  id?: number;
  name: string | null;
  quantity: number;
  price: number;

  constructor(
    name: string | null,
    quantity: number,
    price: number,
    id?: number
  ) {
    this.name = name;
    this.quantity = quantity;
    this.price = price;
    this.id = id;
  }

  static fromBlank(): EstimateItem {
    return new EstimateItem(null, 0, 0);
  }

  static fromForm(estimateItemForm: EstimateItemForm): EstimateItem {
    return new EstimateItem(
      estimateItemForm.name,
      stringToNumber(estimateItemForm.quantity) ? stringToNumber(estimateItemForm.quantity) as number : 0,
      moneyToNumber(estimateItemForm.price) ? moneyToNumber(estimateItemForm.price) as number : 0,
      estimateItemForm.id
    )
  }
}
