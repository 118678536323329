
import { defineComponent } from "vue";
import router from "@/router";

export default defineComponent({
  name: "Header",
  props: {
    brandOnly: { type: Boolean, default: false },
  },
  setup() {
    // expose template variables
    return { router };
  },
});
