<template>
  <div
    class="
      card
      flex-fill
      my-2
      ms-3
      p-3
      bg-light
      d-none d-lg-flex
      text-nowrap
      overflow-auto
    "
  >
    <!-- Revenue -->
    <section class="d-flex mb-4">
      <div class="me-5">
        <h6>Total de Hoje</h6>
        <h4>{{ numberToMoney(revenueToday, "R$ ") }}</h4>
      </div>
      <div class="me-5">
        <h6>Total Este Mês</h6>
        <h4>{{ numberToMoney(revenueThisMonth, "R$ ") }}</h4>
      </div>
    </section>

    <section class="mb-4">
      <h6>Progresso das Vendas Este Mês</h6>
      <div class="progress mb-1">
        <div
          :class="{ 'bg-warning': proportions.partialMonth >= 1 }"
          class="progress-bar progress-bar-striped progress-bar-animated"
          role="progressbar"
          :style="'width: ' + proportions.partialMonth * 100 + '%'"
        ></div>
      </div>
      <div class="d-flex justify-content-between">
        <div>
          <span>Este Mês: </span>
          <span>{{ numberToMoney(revenueThisMonth, "R$ ") }}</span>
          <span v-if="isFinite(proportions.partialMonth)">
            ~ {{ (proportions.partialMonth * 100).toFixed(2) }} %</span
          >
        </div>
        <div>
          <span>Mês Passado: </span>
          <span>{{ numberToMoney(revenueLastMonth, "R$ ") }}</span>
        </div>
      </div>
    </section>

    <section class="mb-4">
      <h6>Formas de Pagamento Este Mês</h6>
      <div class="progress mb-1">
        <div
          class="progress-bar"
          role="progressbar"
          :style="'width: ' + proportions.cash * 100 + '%'"
        ></div>
        <div
          class="progress-bar bg-danger"
          role="progressbar"
          :style="'width: ' + proportions.card * 100 + '%'"
        ></div>
        <div
          class="progress-bar bg-warning"
          role="progressbar"
          :style="'width: ' + proportions.transfer * 100 + '%'"
        ></div>
      </div>
      <div class="d-flex justify-content-between">
        <div class="d-flex flex-column">
          <div>Dinheiro: {{ numberToMoney(cashRevenue, "R$ ") }}</div>
          <div>Cartão: {{ numberToMoney(cardRevenue, "R$ ") }}</div>
          <div>Transferência: {{ numberToMoney(transferRevenue, "R$ ") }}</div>
        </div>
        <div>Total: {{ numberToMoney(revenueThisMonth, "R$ ") }}</div>
      </div>
    </section>

    <section>
      <h6>Filtros</h6>
      <div class="d-flex">
        <div class="me-3">
          <div class="mb-2">Períodos</div>
          <div class="dropdown">
            <button
              class="btn btn-secondary btn-sm dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Hoje
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li><a class="dropdown-item" href="#">Hoje</a></li>
              <li><a class="dropdown-item" href="#">Ontem</a></li>
              <li><a class="dropdown-item" href="#">Anteontem</a></li>
              <li><hr class="dropdown-divider" /></li>
              <li><a class="dropdown-item" href="#">Esta Semana</a></li>
              <li><a class="dropdown-item" href="#">Este Mês</a></li>
            </ul>
          </div>
        </div>
        <div class="me-3">
          <div class="mb-2">Datas</div>
          <div class="dropdown">
            <button
              class="btn btn-outline-secondary btn-sm dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              03/06/2021 (qui)
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li><a class="dropdown-item" href="#">03/06/2021 (qui)</a></li>
              <li><a class="dropdown-item" href="#">02/06/2021 (qua)</a></li>
              <li><a class="dropdown-item" href="#">01/06/2021 (ter)</a></li>
              <li><a class="dropdown-item" href="#">31/05/2021 (seg)</a></li>
              <li><a class="dropdown-item" href="#">30/05/2021 (dom)</a></li>
              <li><a class="dropdown-item" href="#">29/05/2021 (sáb)</a></li>
              <li><a class="dropdown-item" href="#">28/05/2021 (sex)</a></li>
              <li><hr class="dropdown-divider" /></li>
              <li>
                <a class="dropdown-item" href="#">Selecionar outra data...</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="me-4">
          <div class="mb-2">Meses</div>
          <div class="dropdown">
            <button
              class="btn btn-outline-secondary btn-sm dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              junho/2021
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li><a class="dropdown-item" href="#">junho/2021</a></li>
              <li><a class="dropdown-item" href="#">maio/2021</a></li>
              <li><a class="dropdown-item" href="#">abril/2021</a></li>
              <li><a class="dropdown-item" href="#">março/2021</a></li>
              <li><a class="dropdown-item" href="#">fevereiro/2021</a></li>
              <li><a class="dropdown-item" href="#">janeiro/2021</a></li>
              <li><a class="dropdown-item" href="#">dezembro/2020</a></li>
              <li><a class="dropdown-item" href="#">novembro/2020</a></li>
              <li><a class="dropdown-item" href="#">outubro/2020</a></li>
              <li><hr class="dropdown-divider" /></li>
              <li>
                <a class="dropdown-item" href="#">Selecionar outro mês...</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="me-3">
          <div class="mb-2">Formas de Pagamento</div>
          <div class="dropdown">
            <button
              class="btn btn-secondary btn-sm dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Todos os Tipos de Venda
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <a class="dropdown-item" href="#">Todos os Tipos de Venda</a>
              </li>
              <li><a class="dropdown-item" href="#">Dinheiro</a></li>
              <li><a class="dropdown-item" href="#">Crédito</a></li>
              <li><a class="dropdown-item" href="#">Débito</a></li>
              <li><a class="dropdown-item" href="#">Transferência</a></li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue";
import { Filter } from "@/models/base/filter.model";
import { Sale } from "@/models/base/sale.model";
import { numberToMoney } from "@/utils/util";

export default defineComponent({
  name: "SalesSummary",
  props: {
    revenueToday: { type: Number },
    revenueThisMonth: { type: Number },
    revenueLastMonth: { type: Number },
    cashRevenue: { type: Number },
    cardRevenue: { type: Number },
    transferRevenue: { type: Number },
    currentDateFilter: { type: Object as PropType<Filter<Sale>> },
    currentTypeFilter: { type: Object as PropType<Filter<Sale>> },
  },
  setup(props) {
    /**
     * Evaluates the proportion of each type of sale
     */
    const proportions = computed(() => {
      const partialMonth =
        (props.revenueThisMonth ?? 0) / (props.revenueLastMonth ?? 0);
      const cash = (props.cashRevenue ?? 0) / (props.revenueThisMonth ?? 0);
      const card = (props.cardRevenue ?? 0) / (props.revenueThisMonth ?? 0);
      const transfer =
        (props.transferRevenue ?? 0) / (props.revenueThisMonth ?? 0);
      return { cash, card, transfer, partialMonth };
    });

    // expose template variables
    return { numberToMoney, proportions };
  },
});
</script>