
import { defineComponent, PropType } from "vue";
import {
  dateToString,
  moneyToNumber,
  numberToMoney,
  stringToNumber,
} from "@/utils/util";
import { EstimateItemForm } from "@/models/forms/estimate-item.form";

export default defineComponent({
  name: "EstimateItemsTable",
  props: {
    estimateItems: {
      type: Object as PropType<EstimateItemForm[]>,
      default: new Array<EstimateItemForm>(),
    },
  },
  emits: ["onDelete"],
  setup(props, context) {
    /**
     * Evaluates the total price of a product based on setted quantity and unit price
     * @param quantity a string representing the quantity of a product
     * @param unitPrice a string representing the price of a single unit of the product
     * @returns a string representing the total price for a product
     */
    const evaluateTotal = (quantity: string, unitPrice: string): string => {
      const quantityNumber = stringToNumber(quantity);
      const unitPriceNumber = moneyToNumber(unitPrice);

      if (quantityNumber != null && unitPriceNumber != null) {
        return numberToMoney(quantityNumber * unitPriceNumber, "R$ ");
      }

      return "R$ 0,00";
    };

    /**
     * Emits an event when the user clicks in the delete action
     * @param estimateItemIndex the index of the estimate item associated with the delete action
     */
    const onDelete = (estimateItemIndex: number): void => {
      context.emit("onDelete", estimateItemIndex);
    };

    // expose template variables
    return { onDelete, dateToString, numberToMoney, evaluateTotal };
  },
});
