
import { SelectableItem } from "@/models/base/selectable-item.interface";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "SelectableList",
  props: {
    items: {
      type: Object as PropType<SelectableItem[]>,
      default: new Array<SelectableItem>(),
    },
  },
  emits: ["selected"],
  setup(props, context) {
    /**
     * Emits an event when the user selects an item
     */
    const onSelection = (itemId: number): void => {
      context.emit("selected", itemId);
    };

    return { onSelection };
  },
});
