<template>
  <main
    class="container-fluid p-3 mt-header d-flex flex-column align-items-center h-100"
  >
    <!-- Header -->
    <Header brandOnly />

    <!-- Form Section -->
    <div class="mt-2 mt-sm-3 mt-md-4 mt-lg-5 mw-35-rem fadein">
      <!-- First Notice Section -->
      <section class="mb-4">
        <h1 class="mb-2">
          <i class="bi bi-exclamation-diamond"></i>
        </h1>
        <h5>Atenção!</h5>
        <h6>
          O Market Web App utiliza o seu navegador para armazenar dados
          importantes para o seu funcionamento.
        </h6>
      </section>

      <section class="mb-2">💀 Não desinstale o seu navegador</section>

      <section class="mb-2">
        💀 Não limpe os dados de navegação deste site
      </section>

      <section class="mb-4">🎈 Tenha sempre uma cópia dos seus dados</section>

      <!-- Confirmation button -->
      <section>
        <button class="btn btn-primary" @click="onContinue()">
          Entendido!
        </button>
      </section>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Header from "@/components/base/Header.vue";
import router from "@/router";

export default defineComponent({
  name: "FirstNotice",
  components: {
    Header,
  },
  setup() {
    /**
     * Proceeds to the home page
     */
    const onContinue = (): void => {
      router.push("/");
    };

    return { onContinue };
  },
});
</script>

<style lang="scss">
.mw-35-rem {
  max-width: 35rem;
}
</style>
