import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { db } from '@/database/market-db';
import { ConfigService } from '@/services/config-service';
import Home from '../views/home/Home.vue'
import Sales from '../views/sales/Sales.vue'
import Products from '../views/products/Products.vue'
import Customers from '../views/customers/Customers.vue'
import Estimates from '../views/estimates/Estimates.vue'
import Config from '../views/config/Config.vue';
import FirstAccess from '../views/first-access/FirstAccess.vue'
import FirstNotice from '../views/first-notice/FirstNotice.vue'
import Layout from '@/layout/Layout.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/first-access',
    name: 'FirstAccess',
    component: FirstAccess
  },
  {
    path: '/first-notice',
    name: 'FirstNotice',
    component: FirstNotice
  },
  {
    path: '/',
    name: 'Layout',
    redirect: '/home',
    component: Layout,
    children: [
      {
        path: 'home',
        name: 'Home',
        component: Home
      },
      {
        path: 'sales',
        name: 'Sales',
        component: Sales
      },
      {
        path: 'products',
        name: 'Products',
        component: Products
      },
      {
        path: 'customers',
        name: 'Customers',
        component: Customers
      },
      {
        path: 'estimates',
        name: 'Estimates',
        component: Estimates
      },
      {
        path: 'config',
        name: 'Config',
        component: Config
      }
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const configService = new ConfigService(db);

router.beforeEach(async (to) => {
  const config = await configService.get();

  // First access scenario
  if (config == null && to.fullPath !== '/first-access') {
    return '/first-access';
  }

  // Common access scenario
  else if (config != null && to.fullPath === '/first-access') {
    return '/';
  }

  return true;
})

export default router
