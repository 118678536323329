<template>
  <main class="container-fluid flex-fill overflow-auto p-3">
    <!-- Welcome Section -->
    <section class="mb-4">
      <h4>{{ greeting }}, {{ username ? username : "visitante" }}!</h4>
      <h6 v-if="storename">{{ storename }}</h6>
    </section>

    <button class="btn btn-primary mb-4" @click="router.push('/sales')">
      <i class="bi bi-arrow-right me-2"></i>
      <span>Ir para a página de vendas</span>
    </button>

    <!-- Messages section -->
    <section class="mb-4">
      <h4>Mensagens</h4>
      <div class="card bg-danger text-white mb-3" style="width: 36rem">
        <div class="card-header">Atualização v.1.2</div>
        <div class="card-body">
          <h5 class="card-title">
            <i class="bi bi-arrow-repeat me-2"></i>
            <span>Nova geração de orçamentos em PDF!</span>
          </h5>
          <p class="card-text">
            Agora é possível gerar PDFs com os dados de um orçamento clicando no
            botão "<i class="bi bi-printer"></i> Gerar Orçamento" na tela de
            orçamentos.
          </p>
        </div>
      </div>

      <div class="card bg-warning" style="width: 36rem">
        <div class="card-header">Atualização v.1.1</div>
        <div class="card-body">
          <h5 class="card-title">
            <i class="bi bi-arrow-repeat me-2"></i>
            <span>Nova tela de orçamentos!</span>
          </h5>
          <p class="card-text">
            Adicionamos o suporte ao cadastro e geração de orçamentos. Para
            visitar esta nova funcionalidade, selecione "Orçamentos" no menu
            superior ou clique no link abaixo.
          </p>
          <button
            href="#"
            class="btn btn-secondary"
            @click="router.push('/estimates')"
          >
            <i class="bi bi-arrow-right me-2"></i>
            <span>Ir para a página de orçamentos</span>
          </button>
        </div>
      </div>
    </section>
  </main>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { ConfigService } from "@/services/config-service";
import { db } from "@/database/market-db";
import { Config } from "@/models/base/config.model";
import router from "@/router";

export default defineComponent({
  name: "Home",
  setup() {
    const username = ref("");
    const storename = ref("");
    const configService = new ConfigService(db);

    /**
     * Gets the greeting message according to current time
     */
    const greeting = computed(() => {
      const now = new Date().getHours();
      if (now < 6) {
        return "Boa noite";
      } else if (now < 12) {
        return "Bom dia";
      } else if (now < 18) {
        return "Boa tarde";
      } else {
        return "Boa noite";
      }
    });

    configService.get().then((result: Config | undefined) => {
      if (result) {
        username.value = result.username;
        storename.value = result.storename;
      }
    });

    return { username, storename, greeting, router };
  },
});
</script>
