<template>
  <!-- New Estimate Modal -->
  <div
    class="modal fade"
    :id="modalId"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    @click="closeAllDropdowns()"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <!-- Modal header -->
        <div class="modal-header bg-light">
          <h5 class="modal-title">{{ title }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
          ></button>
        </div>

        <!-- Modal body -->
        <div class="modal-body">
          <div class="row">
            <div class="col-8 mb-3 position-relative">
              <label class="form-label">Nome do cliente</label>
              <div class="input-group">
                <span class="input-group-text">@</span>
                <input
                  class="form-control"
                  id="estimateFocus"
                  placeholder="Digite o nome do cliente"
                  v-model="estimate.customerName"
                  @input="handleCustomerSearchInput"
                  @keydown.enter="handleCustomerSelectionKeydown()"
                  autocomplete="off"
                  required
                />
              </div>
              <SelectableList
                v-if="isCustomerDropdownVisible"
                :items="customers"
                @selected="handleCustomerSelection($event)"
              />
            </div>

            <div class="col-4 mb-3">
              <label class="form-label">Data do Orçamento</label>
              <input class="form-control" type="date" v-model="estimate.date" />
            </div>
          </div>

          <div class="row">
            <div class="col-8 mb-3">
              <label class="form-label">Endereço</label>
              <input
                class="form-control"
                placeholder="Digite o endereço do cliente"
                v-model="estimate.customerAddress"
              />
            </div>

            <div class="col-4 mb-3">
              <label class="form-label">Telefone</label>
              <input
                class="form-control"
                placeholder="Digite o telefone do cliente"
                v-model="estimate.customerPhone"
              />
            </div>
          </div>

          <div class="mb-3">
            <label class="form-label"> Itens do Orçamento </label>
            <div class="input-group position-relative">
              <span class="input-group-text">Inserir Item</span>
              <input
                class="form-control"
                id="productSearch"
                placeholder="Digite um produto"
                v-model="productSearch"
                @input="handleProductSearchInput"
                @keydown.enter="handleProductSelectionKeydown()"
                autocomplete="off"
                required
              />
              <button
                class="btn btn-primary"
                @click="addProductFromProductSearch()"
              >
                <i class="bi bi-plus-circle me-2"></i>
                <span>Inserir</span>
              </button>
              <SelectableList
                v-if="isProductDropdownVisible"
                :items="products"
                @selected="handleProductSelection($event)"
              />
            </div>
          </div>

          <EstimateItemsTable
            :estimateItems="estimate.items"
            @onDelete="handleEstimateItemDeletion($event)"
          />
        </div>

        <!-- Modal footer -->
        <div class="modal-footer bg-light">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Cancelar
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="onConfirmation()"
          >
            {{ confirmationLabel }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, onUnmounted, ref } from "vue";
import { ModalMode } from "@/models/enums/modal-mode.enum";
import { Modal } from "bootstrap";
import { Estimate } from "@/models/base/estimate.model";
import { Customer } from "@/models/base/customer.model";
import { Product } from "@/models/base/product.model";
import { EstimateForm } from "@/models/forms/estimate.form";
import { EstimateService } from "@/services/estimate-service";
import { CustomerService } from "@/services/customer-service";
import { ProductService } from "@/services/product-service";
import { db } from "@/database/market-db";
import EstimateItemsTable from "@/components/tables/EstimateItemsTable.vue";
import SelectableList from "@/components/base/SelectableList.vue";
import { EstimateItemForm } from "@/models/forms/estimate-item.form";
import { EstimateItem } from "@/models/base/estimate-item.model";

export default defineComponent({
  name: "EstimateModal",
  components: {
    EstimateItemsTable,
    SelectableList,
  },
  props: {
    modalId: { type: String, required: true },
  },
  emits: ["created", "updated"],
  setup(props, context) {
    const mode = ref(ModalMode.CREATE);
    const estimate = ref(new EstimateForm());
    const customers = ref(new Array<Customer>());
    const products = ref(new Array<Product>());
    const estimateService = new EstimateService(db);
    const customerService = new CustomerService(db);
    const productService = new ProductService(db);

    const productSearch = ref("");
    const isCustomerDropdownVisible = ref(false);
    const isProductDropdownVisible = ref(false);

    let estimateModal: Modal | null;

    onMounted(() => {
      const modalElement = document.getElementById(props.modalId);
      const inputElement = document.getElementById("estimateFocus");
      estimateModal = modalElement ? new Modal(modalElement) : null;
      modalElement?.addEventListener("shown.bs.modal", () =>
        inputElement?.focus()
      );
    });

    onUnmounted(() => {
      if (estimateModal) {
        estimateModal.dispose();
      }
    });

    /**
     * Computes the title of the modal
     */
    const title = computed(() => {
      if (mode.value === ModalMode.EDIT) return "Editar Orçamento";
      if (mode.value === ModalMode.CREATE) return "Cadastrar Orçamento";
      throw new Error("Could not get the title from the specified modal mode");
    });

    /**
     * Computes the label of the confirmation button of the modal
     */
    const confirmationLabel = computed(() => {
      if (mode.value === ModalMode.EDIT) return "Atualizar";
      if (mode.value === ModalMode.CREATE) return "Cadastrar";
      throw new Error(
        "Could not get the confirmation label from the specified modal mode"
      );
    });

    /**
     * Open the modal in a specific mode and with specific data
     * @param openMode the mode to open the modal
     * @param openEstimate the estimate to load in the modal
     */
    const open = (openMode: ModalMode, openEstimate?: Estimate): void => {
      mode.value = openMode;
      estimate.value = new EstimateForm(openEstimate);
      isCustomerDropdownVisible.value = false;
      isProductDropdownVisible.value = false;
      productSearch.value = "";

      updateCustomerData();
      updateProductData();

      if (estimateModal) {
        estimateModal.show();
      } else {
        throw new Error("Could not find the modal to open");
      }
    };

    /**
     * Close the modal
     */
    const close = (): void => {
      if (estimateModal) {
        estimateModal.hide();
      } else {
        throw new Error("Could not find the modal to close");
      }
    };

    /**
     * Handle the click in the confirmation button
     */
    const onConfirmation = (): void => {
      const estimateToPut = Estimate.fromForm(estimate.value);
      estimateService.postOrPut(estimateToPut).then(() => {
        switch (mode.value) {
          case ModalMode.CREATE:
            context.emit("created");
            break;
          case ModalMode.EDIT:
            context.emit("updated");
            break;
          default:
            throw new Error("Unknown mode for the customer modal");
        }

        close();
      });
    };

    /**
     * Handles customer name input search
     */
    const handleCustomerSearchInput = (): void => {
      if (!estimate.value.customerName) {
        isCustomerDropdownVisible.value = false;
        return;
      }

      customerService
        .searchByName(estimate.value.customerName)
        .then((customersData: Customer[]) => {
          customers.value = customersData;
          isCustomerDropdownVisible.value = true;
        });
    };

    /**
     * Handles customer selection
     */
    const handleCustomerSelection = (customerId: number): void => {
      const customer = customers.value.find(
        (customer: Customer) => customer.id === customerId
      );

      addCustomerDataToEstimate(customer);
    };

    /**
     * Handles customer selection during enter keydown
     */
    const handleCustomerSelectionKeydown = (): void => {
      if (customers.value.length !== 1) {
        return;
      }

      isCustomerDropdownVisible.value = false;
      const customer = customers.value[0];

      addCustomerDataToEstimate(customer);
    };

    /**
     * Adds the provided data from a customer into the estimate fields and
     * focus onto the next common field
     * @param customer the customer to be used as data to fill the estimate fields
     */
    const addCustomerDataToEstimate = (customer?: Customer): void => {
      if (customer) {
        estimate.value.customerName = customer.name;
        estimate.value.customerAddress = customer.address;
        estimate.value.customerPhone = customer.phone;
      }

      const productSearchElement = document.getElementById("productSearch");
      if (productSearchElement) {
        productSearchElement.focus();
      }
    };

    /**
     * Handles product input search
     */
    const handleProductSearchInput = (): void => {
      if (!productSearch.value) {
        isProductDropdownVisible.value = false;
        return;
      }

      productService
        .searchByNameAndCategory(productSearch.value)
        .then((productsData: Product[]) => {
          products.value = productsData;
          isProductDropdownVisible.value = true;
        });
    };

    /**
     * Handles product selection
     */
    const handleProductSelection = (productId: number): void => {
      const product = products.value.find(
        (product: Product) => product.id === productId
      );

      if (product) {
        productSearch.value = "";
        addProductDataToEstimate(product);
      }
    };

    /**
     * Handles product selection during enter keydown
     */
    const handleProductSelectionKeydown = (): void => {
      isProductDropdownVisible.value = false;

      if (products.value.length === 1) {
        const product = products.value[0];
        if (product) {
          productSearch.value = "";
          addProductDataToEstimate(product);
        }
      } else if (products.value.length === 0 && productSearch.value != "") {
        addProductFromProductSearch();
      }
    };

    /**
     * Adds a new product in the estimate items based on the content of
     * the product search input
     */
    const addProductFromProductSearch = (): void => {
      const estimateItem = new EstimateItem(productSearch.value, 1, 0);
      estimate.value.items.push(new EstimateItemForm(estimateItem));
    };

    /**
     * Adds the provided product data into the estimate items
     * @param product the product to be added to the estimate items
     */
    const addProductDataToEstimate = (product: Product): void => {
      const estimateItem = new EstimateItem(
        product.name,
        1,
        product.price1 ?? 0,
        product.id
      );

      estimate.value.items.push(new EstimateItemForm(estimateItem));
    };

    /**
     * Handles an estimate item deletion
     * @param estimateItemIndex the index of the estimate item to be removed
     */
    const handleEstimateItemDeletion = (estimateItemIndex: number): void => {
      estimate.value.items.splice(estimateItemIndex, 1);
    };

    /**
     * Updates the customer data by fetching from the database
     */
    const updateCustomerData = (): void => {
      customerService
        .searchByName(estimate.value.customerName)
        .then((customersData: Customer[]) => {
          customers.value = customersData;
        });
    };

    /**
     * Updates the product data by fetching from the database
     */
    const updateProductData = (): void => {
      productService
        .searchByNameAndCategory(productSearch.value)
        .then((productsData: Product[]) => {
          products.value = productsData;
        });
    };

    /**
     * Closes all opened dropdowns
     */
    const closeAllDropdowns = (): void => {
      isCustomerDropdownVisible.value = false;
      isProductDropdownVisible.value = false;
    };

    return {
      title,
      confirmationLabel,
      estimate,
      customers,
      products,
      productSearch,
      isCustomerDropdownVisible,
      isProductDropdownVisible,
      open,
      close,
      onConfirmation,
      closeAllDropdowns,
      handleCustomerSearchInput,
      handleCustomerSelection,
      handleCustomerSelectionKeydown,
      handleProductSearchInput,
      handleProductSelection,
      handleProductSelectionKeydown,
      handleEstimateItemDeletion,
      addProductFromProductSearch,
    };
  },
});
</script>
