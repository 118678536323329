
import { defineComponent, PropType } from "vue";
import { Customer } from "@/models/base/customer.model";

export default defineComponent({
  name: "CustomerTable",
  props: {
    customers: {
      type: Object as PropType<Customer[]>,
      default: new Array<Customer>(),
    },
  },
  emits: ["onEdit", "onDelete"],
  setup(props, context) {
    /**
     * Emits an event when the user clicks in the edit action
     * @param customer the customer associated with the edit action
     */
    const onEdit = (customer: Customer): void => {
      context.emit("onEdit", customer);
    };

    /**
     * Emits an event when the user clicks in the delete action
     * @param customer the customer associated with the delete action
     */
    const onDelete = (customer: Customer): void => {
      context.emit("onDelete", customer);
    };

    // expose template variables
    return { onEdit, onDelete };
  },
});
