/* eslint-disable @typescript-eslint/no-explicit-any */

import { EstimateItem } from "@/models/base/estimate-item.model";
import { Estimate } from "@/models/base/estimate.model";
import { numberToMoney, numberToString } from "@/utils/util";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

export function generateEstimateReport(estimate: Estimate): void {
  const doc = new jsPDF();
  const estimateTitle = "Orçamento";
  const companyTitle = "Sales Portas e Cia";
  const companySubtitle = "Portas | Batentes | Guarnições | Fechaduras | Dobradiças";
  const companyInfo1 = "Av. Manoel Ruz Peres, 5218, Jardim Veneza";
  const companyInfo2 = "CEP: 13348-855 - Indaiatuba/SP";
  const companyInfo3 = "Whatsapp: (19) 99325-5915";
  const contactTitle = "Dados de Contato";
  const itemsTitle = "Itens do Orçamento";
  const filename = `Orçamento_${estimate.id}.pdf`;

  const customerName = estimate.customerName;
  const customerAddress = estimate.customerAddress;
  const customerPhone = estimate.customerPhone;
  const estimateDate = estimate.date ? estimate.date.toLocaleDateString('pt-BR') : "";

  autoTable(doc, { head: [[estimateTitle]] });

  doc.text(companyTitle, 15, 30);

  doc.setFontSize(9);
  doc.text(companySubtitle, 15, 35);
  doc.text(companyInfo1, 15, 42);
  doc.text(companyInfo2, 15, 47);
  doc.text(companyInfo3, 15, 52);

  autoTable(doc, { head: [[contactTitle]], startY: 57 });

  autoTable(doc, {
    head: [["Nome do cliente", "Data do orçamento"]],
    body: [[customerName, estimateDate]],
    theme: "grid",
    startY: 67,
  });

  autoTable(doc, {
    head: [["Endereço", "Telefone"]],
    body: [[customerAddress, customerPhone]],
    theme: "grid",
    startY: 82,
  });

  autoTable(doc, { head: [[itemsTitle]], startY: 102 });

  const items = estimate.items.map((estimateItem: EstimateItem) => {
    return [
      estimateItem.id ? estimateItem.id.toString() : "",
      estimateItem.name,
      numberToString(estimateItem.quantity),
      numberToMoney(estimateItem.price, "R$ "),
      numberToMoney(estimateItem.quantity * estimateItem.price, "R$ "),
    ];
  });

  autoTable(doc, {
    head: [[
      "Cód.",
      "Descrição do Produto",
      "Quantidade",
      "Preço Unitário",
      "Preço Total",
    ]],
    body: items,
    theme: "grid",
    startY: 112,
  });

  const lastTablePosition = (doc as any).previousAutoTable.finalY;

  autoTable(doc, {
    startY: lastTablePosition + 5,
    theme: "grid",
    styles: { halign: "right" },
    head: [["Valor Total"]],
    body: [[numberToMoney(estimateTotal(estimate.items), "R$ ")]],
  });

  const numberOfPages = (doc.internal as any).getNumberOfPages();

  for (let currentPage = 1; currentPage <= numberOfPages; currentPage++) {
    doc.setPage(currentPage);
    const pageInformation = `Página ${currentPage} de ${numberOfPages}`;
    const pageHeight = doc.internal.pageSize.getHeight();
    doc.text(pageInformation, 15, pageHeight - 10);
  }

  doc.save(filename);
}

/**
 * Computes the total associated with the items of an estimate
 * @param estimateItems the items in the estimate
 */
const estimateTotal = (estimateItems: EstimateItem[]): number => {
  return estimateItems
    .map(
      (estimateItem: EstimateItem) =>
        estimateItem.quantity * estimateItem.price
    )
    .reduce((previous, current) => previous + current, 0);
};