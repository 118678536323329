
import { defineComponent, PropType } from "vue";
import { Sale } from "@/models/base/sale.model";
import { numberToMoney, dateToString, typeToString } from "@/utils/util";

export default defineComponent({
  name: "SalesTable",
  props: {
    sales: {
      type: Object as PropType<Sale[]>,
      default: new Array<Sale>(),
    },
  },
  emits: ["onEdit", "onDelete"],
  setup(props, context) {
    /**
     * Emits an event when the user clicks in the edit action
     * @param sale the sale associated with the edit action
     */
    const onEdit = (sale: Sale): void => {
      context.emit("onEdit", sale);
    };

    /**
     * Emits an event when the user clicks in the delete action
     * @param product the sale associated with the delete action
     */
    const onDelete = (sale: Sale): void => {
      context.emit("onDelete", sale);
    };

    // expose template variables
    return { onEdit, onDelete, numberToMoney, dateToString, typeToString };
  },
});
