<template>
  <table class="table table-sm table-hover">
    <!-- Table header -->
    <thead>
      <tr>
        <th scope="col" class="px-2 text-nowrap sticky-top bg-white">Cód.</th>
        <th scope="col" class="px-2 text-nowrap sticky-top bg-white">
          Nome do cliente
        </th>
        <th scope="col" class="px-2 text-nowrap sticky-top bg-white">
          Endereço
        </th>
        <th scope="col" class="px-2 text-nowrap sticky-top bg-white">
          Telefone
        </th>
        <th scope="col" class="px-2 text-nowrap sticky-top bg-white">Ações</th>
      </tr>
    </thead>

    <!-- Table body -->
    <tbody>
      <tr v-for="customer in customers" :key="customer.id">
        <td class="px-2 text-nowrap">
          {{ customer.id }}
        </td>
        <td class="px-2">
          {{ customer.name }}
        </td>
        <td class="px-2">
          {{ customer.address }}
        </td>
        <td class="px-2">
          {{ customer.phone }}
        </td>
        <td class="px-2 text-nowrap">
          <a class="cursor-pointer text-dark" @click="onEdit(customer)">
            <i class="bi bi-pencil-square me-2"></i>
          </a>
          <a class="cursor-pointer text-dark" @click="onDelete(customer)">
            <i class="bi bi-trash"></i>
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Customer } from "@/models/base/customer.model";

export default defineComponent({
  name: "CustomerTable",
  props: {
    customers: {
      type: Object as PropType<Customer[]>,
      default: new Array<Customer>(),
    },
  },
  emits: ["onEdit", "onDelete"],
  setup(props, context) {
    /**
     * Emits an event when the user clicks in the edit action
     * @param customer the customer associated with the edit action
     */
    const onEdit = (customer: Customer): void => {
      context.emit("onEdit", customer);
    };

    /**
     * Emits an event when the user clicks in the delete action
     * @param customer the customer associated with the delete action
     */
    const onDelete = (customer: Customer): void => {
      context.emit("onDelete", customer);
    };

    // expose template variables
    return { onEdit, onDelete };
  },
});
</script>
