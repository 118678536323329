
import { defineComponent } from "vue";
import Header from "@/components/base/Header.vue";
import router from "@/router";

export default defineComponent({
  name: "FirstNotice",
  components: {
    Header,
  },
  setup() {
    /**
     * Proceeds to the home page
     */
    const onContinue = (): void => {
      router.push("/");
    };

    return { onContinue };
  },
});
