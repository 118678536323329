
import { defineComponent, PropType } from "vue";
import { Estimate } from "@/models/base/estimate.model";
import { EstimateItem } from "@/models/base/estimate-item.model";
import { dateToString, numberToMoney } from "@/utils/util";
import { generateEstimateReport } from "@/templates/estimate-report";

export default defineComponent({
  name: "EstimateTable",
  props: {
    estimates: {
      type: Object as PropType<Estimate[]>,
      default: new Array<Estimate>(),
    },
  },
  emits: ["onEdit", "onDelete"],
  setup(props, context) {
    /**
     * Computes the total associated with the items of an estimate
     * @param estimateItems the items in the estimate
     */
    const estimateTotal = (estimateItems: EstimateItem[]): number => {
      return estimateItems
        .map(
          (estimateItem: EstimateItem) =>
            estimateItem.quantity * estimateItem.price
        )
        .reduce((previous, current) => previous + current, 0);
    };

    /**
     * Emits an event when the user clicks in the edit action
     * @param estimate the estimate associated with the edit action
     */
    const onEdit = (estimate: Estimate): void => {
      context.emit("onEdit", estimate);
    };

    /**
     * Emits an event when the user clicks in the delete action
     * @param estimate the estimate associated with the delete action
     */
    const onDelete = (estimate: Estimate): void => {
      context.emit("onDelete", estimate);
    };

    /**
     * Generates a PDF with the estimate data
     * @param estimate the estimate to be used when generating the PDF
     */
    const generateEstimatePdf = (estimate: Estimate): void => {
      generateEstimateReport(estimate);
    };

    // expose template variables
    return {
      onEdit,
      onDelete,
      dateToString,
      numberToMoney,
      estimateTotal,
      generateEstimatePdf,
    };
  },
});
