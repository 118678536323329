<template>
  <div class="d-flex flex-column h-100">
    <div>
      <Header />
    </div>
    <div class="d-flex flex-column flex-fill mt-header overflow-hidden">
      <RouterView v-slot="{ Component }">
        <Transition name="fade" mode="out-in">
          <Component :is="Component" />
        </Transition>
      </RouterView>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Header from "@/components/base/Header.vue";

export default defineComponent({
  name: "Layout",
  components: {
    Header,
  },
});
</script>

<style lang="scss" scoped>
@import "@/styles/global";
</style>
