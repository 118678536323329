import { Customer } from "../base/customer.model";

/**
 * Class that represents a customer form data
 */
export class CustomerForm {
  id?: number;
  name: string;
  address: string;
  phone: string;

  constructor(customer?: Customer) {
    if (customer) {
      this.id = customer.id;
      this.name = customer.name;
      this.address = customer.address;
      this.phone = customer.phone;
    } else {
      this.name = '';
      this.address = '';
      this.phone = '';
    }
  }
}
