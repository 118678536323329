import { MarketDb } from "@/database/market-db";

/**
 * Class that represents a service with basic functionality
 */
export class BaseService {
  /**
   * The instance of the database to use
   */
  public db: MarketDb;

  /**
   * Constructor
   */
  constructor(db: MarketDb) {
    this.db = db;
  }
}