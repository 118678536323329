import { SelectableItem } from "./selectable-item.interface";

/**
 * Class that represents a category that can be assigned to products
 */
export class Category implements SelectableItem {
  id?: number;
  name: string;

  /**
   * Constructor
   */
  constructor(name: string, id?: number) {
    this.id = id;
    this.name = name;
  }

  static fromName(name: string): Category {
    return new Category(name);
  }

  static fromIdAndName(id: number, name: string): Category {
    return new Category(name, id);
  }
}
