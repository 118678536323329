
import { defineComponent, nextTick, ref } from "vue";
import { ModalMode } from "@/models/enums/modal-mode.enum";
import { Sale } from "@/models/base/sale.model";
import { SaleForm } from "@/models/forms/sale.form";
import { SaleService } from "@/services/sale-service";
import { db } from "@/database/market-db";
import { numberToMoney } from "@/utils/util";
import SalesTable from "@/components/tables/SalesTable.vue";
import SaleModal from "@/components/modals/SaleModal.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import Toast from "@/components/base/Toast.vue";
import SalesSummary from "@/components/base/SalesSummary.vue";
import { SaleType } from "@/models/enums/sale-type.enum";

export default defineComponent({
  name: "Sales",
  components: {
    SalesTable,
    SaleModal,
    ConfirmationModal,
    Toast,
    SalesSummary,
  },
  setup() {
    const sales = ref(new Array<Sale>());
    const saleService = new SaleService(db);
    const saleInput = ref("");
    const salePreview = ref(Sale.fromBlank());
    const saleFormPreview = ref(SaleForm.fromObjWithPreview(salePreview.value));
    const saleModal = ref<InstanceType<typeof SaleModal>>();
    const confirmationModal = ref<InstanceType<typeof ConfirmationModal>>();
    const saleToDelete = ref<string | null>(null);
    const saleIdToDelete = ref<number | null>(null);
    const feedbackToast = ref<InstanceType<typeof Toast>>();

    // Dashboard data
    const revenueToday = ref(0);
    const revenueThisMonth = ref(0);
    const revenueLastMonth = ref(0);
    const cashRevenue = ref(0);
    const cardRevenue = ref(0);
    const transferRevenue = ref(0);

    /**
     * Opens the sale modal in create mode
     */
    const openSaleModalForCreation = (): void => {
      saleModal.value?.open(ModalMode.CREATE);
    };

    /**
     * Opens the sale modal in edit mode
     * @param sale the sale to be opened in the modal
     */
    const openSaleModalForEdition = (sale: Sale): void => {
      saleModal.value?.open(ModalMode.EDIT, sale);
    };

    /**
     * Opens the confirmation modal for deletion
     * @param sale the sale to setted to deletion
     */
    const openConfirmationModalForDeletion = (sale: Sale): void => {
      saleToDelete.value = numberToMoney(sale.value, " R$ ");
      saleIdToDelete.value = sale.id ?? null;
      confirmationModal.value?.open();
    };

    /**
     * Handles the user input in the generic sale input field
     */
    const handleSaleInput = (): void => {
      salePreview.value = Sale.fromSaleInput(saleInput.value);
      saleFormPreview.value = SaleForm.fromObjWithPreview(salePreview.value);
    };

    /**
     * Handles the creation of a sale
     */
    const handleSaleCreation = (): void => {
      updateSaleData(true);
      feedbackToast.value?.open("Venda inserida com sucesso");
    };

    /**
     * Handles the sale creation based on the generic input
     */
    const handleSaleCreationFromInput = (): void => {
      const saleToPost = Sale.fromObj(saleFormPreview.value);

      if (saleToPost.value == null) {
        return;
      }

      saleService.postOrPut(saleToPost).then(() => {
        feedbackToast.value?.open("Venda inserida com sucesso");
        saleInput.value = "";
        handleSaleInput();
        updateSaleData(true);
      });
    };

    /**
     * Handles the update of a sale
     */
    const handleSaleUpdate = (): void => {
      updateSaleData();
      feedbackToast.value?.open("Venda atualizada com sucesso");
    };

    /**
     * Handles the deletion of a sale
     */
    const handleSaleDeletion = (): void => {
      if (saleIdToDelete.value) {
        saleService.delete(saleIdToDelete.value).then(() => {
          updateSaleData();
          feedbackToast.value?.open("Venda excluída com sucesso");
        });
      } else {
        throw new Error("Could not handle the sale deletion");
      }
    };

    /**
     * Updates sale data by fetching from the database
     * @param scroll a flag indicating if the list will scroll to bottom
     */
    const updateSaleData = (scroll = false): void => {
      saleService
        .getAll()
        .then((result: Sale[]) => {
          sales.value = result;
          return nextTick();
        })
        .then(() => {
          if (scroll) scrollToBottom();
          updateDashboardData();
        });
    };

    /**
     * Updates dashboard data by fetching from the database
     */
    const updateDashboardData = async (): Promise<void> => {
      const todaySales = await saleService.getToday();
      revenueToday.value = todaySales
        .map((sale: Sale) => sale.value)
        .reduce((prev, next) => (prev ?? 0) + (next ?? 0), 0) as number;

      const thisMonthSales = await saleService.getCurrentMonth();
      revenueThisMonth.value = thisMonthSales
        .map((sale: Sale) => sale.value)
        .reduce((prev, next) => (prev ?? 0) + (next ?? 0), 0) as number;

      const lastMonthSales = await saleService.getLastMonth();
      revenueLastMonth.value = lastMonthSales
        .map((sale: Sale) => sale.value)
        .reduce((prev, next) => (prev ?? 0) + (next ?? 0), 0) as number;

      cashRevenue.value = thisMonthSales
        .filter((sale: Sale) => sale.type === SaleType.CASH)
        .map((sale: Sale) => sale.value)
        .reduce((prev, next) => (prev ?? 0) + (next ?? 0), 0) as number;

      cardRevenue.value = thisMonthSales
        .filter(
          (sale: Sale) =>
            sale.type === SaleType.CREDIT || sale.type === SaleType.DEBIT
        )
        .map((sale: Sale) => sale.value)
        .reduce((prev, next) => (prev ?? 0) + (next ?? 0), 0) as number;

      transferRevenue.value = thisMonthSales
        .filter((sale: Sale) => sale.type === SaleType.TRANSFER)
        .map((sale: Sale) => sale.value)
        .reduce((prev, next) => (prev ?? 0) + (next ?? 0), 0) as number;
    };

    /**
     * Scrolls the sales table to the bottom
     */
    const scrollToBottom = (): void => {
      const container = document.getElementById("salesTableContainer");
      if (container) {
        container.scrollTop = container.scrollHeight;
      }
    };

    // Initialization
    updateSaleData();

    return {
      sales,
      saleModal,
      saleInput,
      salePreview,
      saleFormPreview,
      openSaleModalForCreation,
      openSaleModalForEdition,
      openConfirmationModalForDeletion,
      confirmationModal,
      saleToDelete,
      saleIdToDelete,
      handleSaleDeletion,
      feedbackToast,
      handleSaleCreation,
      handleSaleCreationFromInput,
      handleSaleUpdate,
      handleSaleInput,
      revenueToday,
      revenueThisMonth,
      revenueLastMonth,
      cashRevenue,
      cardRevenue,
      transferRevenue,
    };
  },
});
