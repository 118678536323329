<template>
  <!-- Category Modal -->
  <div
    class="modal fade"
    :id="modalId"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <!-- Modal header -->
        <div class="modal-header bg-light">
          <h5 class="modal-title">Editar Categorias</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
          ></button>
        </div>

        <!-- Modal body -->
        <div class="modal-body">
          <!-- Create mode -->
          <div v-if="mode === ModalMode.CREATE" class="fadein">
            <div class="mb-3">
              <label for="categoryName" class="form-label"
                >Inserir Nova Categoria</label
              >
              <div class="input-group">
                <span class="input-group-text">@</span>
                <input
                  class="form-control"
                  id="categoryName"
                  placeholder="Digite o nome da categoria"
                  v-model="categoryName"
                  @keydown.enter="createCategory()"
                />
                <button class="btn btn-primary" @click="createCategory()">
                  <i class="bi bi-circle-plus"></i>
                  <span>Adicionar</span>
                </button>
              </div>
            </div>

            <ul class="list-group">
              <li
                v-for="category of categories"
                :key="category.id"
                class="list-group-item d-flex justify-content-between"
              >
                <span>{{ category.name }}</span>
                <div>
                  <i
                    class="bi bi-pencil-square me-2 cursor-pointer"
                    @click="goToEditionMode(category.id)"
                  ></i>
                  <i
                    class="bi bi-trash cursor-pointer"
                    @click="goToDeletionMode(category.id)"
                  ></i>
                </div>
              </li>
            </ul>
          </div>

          <!-- Edit mode -->
          <div v-if="mode === ModalMode.EDIT" class="fadein">
            <div class="mb-3">
              <label for="categoryRename" class="form-label"
                >Renomear categoria</label
              >
              <div class="input-group">
                <span class="input-group-text">@</span>
                <input
                  class="form-control"
                  id="categoryRename"
                  placeholder="Digite o nome da categoria"
                  v-model="categoryRename"
                />
              </div>
            </div>
          </div>

          <!-- Delete mode -->
          <div v-if="mode === ModalMode.DELETE" class="fadein">
            <div>
              A categoria <strong>{{ categoryDelete }}</strong> será excluída.
              Os produtos pertencentes a esta categoria serão mantidos na
              aplicação. Esta ação é irreversível. Deseja continuar?
            </div>
          </div>
        </div>

        <!-- Modal footer -->
        <div class="modal-footer bg-light">
          <button
            v-if="mode === ModalMode.CREATE"
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
          >
            Concluído
          </button>
          <button
            v-if="mode === ModalMode.EDIT"
            type="button"
            class="btn btn-primary"
            @click="renameCategory()"
          >
            Renomear
          </button>
          <button
            v-if="mode === ModalMode.DELETE"
            type="button"
            class="btn btn-secondary"
            @click="cancelDeletion()"
          >
            Cancelar
          </button>
          <button
            v-if="mode === ModalMode.DELETE"
            type="button"
            class="btn btn-danger"
            @click="deleteCategory()"
          >
            Sim, Excluir
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted, Ref, ref } from "vue";
import { Modal } from "bootstrap";
import { ModalMode } from "@/models/enums/modal-mode.enum";
import { Category } from "@/models/base/category.model";
import { CategoryService } from "@/services/category-service";
import { db } from "@/database/market-db";

export default defineComponent({
  name: "CategoryModal",
  props: {
    modalId: { type: String, required: true },
  },
  emits: ["updated"],
  setup(props, context) {
    const mode = ref(ModalMode.CREATE);
    const categories = ref(new Array<Category>());
    const categoryName = ref("");
    const categoryRename = ref("");
    const categoryDelete = ref("");
    const categoryEditId: Ref<number | null> = ref(null);
    const categoryDeleteId: Ref<number | null> = ref(null);
    const categoryService = new CategoryService(db);
    let categoryModal: Modal | null;

    onMounted(() => {
      const modalElement = document.getElementById(props.modalId);
      const inputElement = document.getElementById("categoryName");
      categoryModal = modalElement ? new Modal(modalElement) : null;
      modalElement?.addEventListener("shown.bs.modal", () =>
        inputElement?.focus()
      );
    });

    onUnmounted(() => {
      if (categoryModal) {
        categoryModal.dispose();
      }
    });

    /**
     * Opens the modal
     */
    const open = (): void => {
      getCategories();
      mode.value = ModalMode.CREATE;
      categoryName.value = "";
      if (categoryModal) {
        categoryModal.show();
      } else {
        throw new Error("Could not find the modal to open");
      }
    };

    /**
     * Closes the modal
     */
    const close = (): void => {
      if (categoryModal) {
        categoryModal.hide();
      } else {
        throw new Error("Could not find the modal to close");
      }
    };

    /**
     * Create a category based on the provided name
     */
    const createCategory = (): void => {
      if (!categoryName.value) return;
      categoryService
        .postOrPut(Category.fromName(categoryName.value))
        .then(() => {
          categoryName.value = "";
          getCategories();
        });
    };

    /**
     * Makes the modal enter in edit mode for a specific category
     * @param id the id of the category to edit
     */
    const goToEditionMode = (id: number): void => {
      const category = categories.value.find(
        (category: Category) => category.id === id
      );
      if (category) {
        categoryEditId.value = id;
        categoryRename.value = category.name;
        mode.value = ModalMode.EDIT;
      }
    };

    /**
     * Makes the modal enter in delete mode for a specific category
     * @param id the id of the category to delete
     */
    const goToDeletionMode = (id: number): void => {
      const category = categories.value.find(
        (category: Category) => category.id === id
      );
      if (category) {
        categoryDeleteId.value = id;
        categoryDelete.value = category.name;
        mode.value = ModalMode.DELETE;
      }
    };

    /**
     * Renames the category to the new name provided
     */
    const renameCategory = (): void => {
      if (categoryEditId.value) {
        categoryService
          .postOrPut(
            Category.fromIdAndName(categoryEditId.value, categoryRename.value)
          )
          .then(() => {
            getCategories();
          });
      }
    };

    /**
     * Renames the category to the new name provided
     */
    const deleteCategory = (): void => {
      if (categoryDeleteId.value) {
        categoryService.delete(categoryDeleteId.value).then(() => {
          getCategories();
        });
      }
    };

    /**
     * Cancel the deletion and returns to the creation mode
     */
    const cancelDeletion = (): void => {
      mode.value = ModalMode.CREATE;
    };

    /**
     * Gets all the categories from the database
     */
    const getCategories = (): void => {
      categoryService.getAll().then((result: Category[]) => {
        categories.value = result;
        mode.value = ModalMode.CREATE;
        context.emit("updated");
      });
    };

    // exposing template variables
    return {
      mode,
      open,
      close,
      categories,
      categoryName,
      categoryRename,
      categoryDelete,
      createCategory,
      goToEditionMode,
      goToDeletionMode,
      renameCategory,
      deleteCategory,
      cancelDeletion,
      ModalMode,
    };
  },
});
</script>
