import { BaseService } from './base/base-service';
import { MarketDb } from "@/database/market-db";
import { Category } from './../models/base/category.model';

/**
 * Class that represents a collection of methods related to category operations
 */
export class CategoryService extends BaseService {
  /**
   * Constructor
   */
  constructor(db: MarketDb) {
    // call super
    super(db);
  }

  /**
   * Gets all categories from the database
   * @returns a promise that resolves to a list of all categories
   */
  public async getAll(): Promise<Category[]> {
    return this.db.categories.toArray();
  }

  /**
   * Posts or Puts a new category into the database
   * @param category the category to be posted
   * @returns a promise that resolves to the generated or updated ID
   */
  public async postOrPut(category: Category): Promise<number> {
    return this.db.categories.put(category);
  }

  /**
   * 
   * @param id the id of the category to be deleted
   * @returns returns an empty promise
   */
  public async delete(id: number): Promise<void> {
    return this.db.categories.delete(id);
  }
}