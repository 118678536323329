/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

/**
 * Utility functions to convert objects and perform common tasks
 * WARNING: The eslint checks are disabled to allow generic conversions of objects
 * Use these utilities with caution!
 */

/**
 * Converts a monetary string to its decimal value
 * @param money the monetary string to be converted to decimal
 * @returns the decimal representation of the monetary string or null if empty string
 */
export function moneyToNumber(money: string): number | null {
  const pattern = /^(?:\$|R\$)? *([.,]\d+|\d+[.,]?\d*)/;
  const result = pattern.exec(money);
  if (result) {
    const parseResult = parseFloat(result[1].replace(",", "."));
    if (!isNaN(parseResult)) return parseResult;
  }
  return null;
}

/**
 * Converts a decimal value to its monetary string
 * @param number the number to be converted to the monetary string
 * @returns the monetary string representation of the number or an empty string if null
 */
export function numberToMoney(number: number | null, prefix?: string, suffix?: string): string {
  return number != null && isFinite(number)
    ? `${prefix ? prefix : ""}${number.toFixed(2).toString().replace('.', ',')}${suffix ? suffix : ""}`
    : ''
}

/**
 * Converts a numeric string to its decimal value
 * @param numeric the numeric string to be converted to decimal
 * @returns the decimal representation of the numeric string or null if empty string
 */
export function stringToNumber(numeric: string): number | null {
  const pattern = /^(?:\$|R\$)? *([.,]\d+|\d+[.,]?\d*)/;
  const result = pattern.exec(numeric);
  if (result) {
    const parseResult = parseFloat(result[1].replace(",", "."));
    if (!isNaN(parseResult)) return parseResult;
  }
  return null;
}

/**
 * Converts a decimal value to its numeric string
 * @param number the number to be converted to the numeric string
 * @returns the numeric string representation of the number or an empty string if null
 */
export function numberToString(number: number | null, prefix?: string, suffix?: string): string {
  return number != null && isFinite(number)
    ? `${prefix ? prefix : ""}${number.toString().replace('.', ',')}${suffix ? suffix : ""}`
    : ''
}

/**
 * Returns a string in the YYYY-MM-DD format from a Date object
 * @param date the date to be converted
 * @returns the string representing the date or an empty string
 */
export function dateToString(date: Date | null): string {
  if (!date) { return ''; }

  // getting the year as a string
  const year = date.getFullYear().toString();

  // getting the month as a string
  const rawMonth = (date.getMonth() + 1).toString();
  const month = rawMonth.length == 1 ? `0${rawMonth}` : rawMonth;

  // getting the day as a string
  const rawDay = date.getDate().toString();
  const day = rawDay.length == 1 ? `0${rawDay}` : rawDay;

  // returning formatted date
  return `${year}-${month}-${day}`;
}

/**
 * Returns a string in the YYYY-MM-DD format as a Date object
 * @param date the string to be converted to a Date object
 * @returns the date as converted from the string or null
 */
export function stringToDate(date: string): Date | null {
  const dateMask1 = /(\d{4})-(\d{2})-(\d{2})/;
  const dateMask2 = /(\d\d?)[/|-](\d\d?)[/|-](\d\d?\d?\d?\d?)/;
  const dateMask3 = /(\d\d?)[/|-](\d\d?)/;

  const result1 = dateMask1.exec(date);
  if (result1) {
    const year: number = parseInt(result1[1], 10);
    const month: number = parseInt(result1[2], 10);
    const date: number = parseInt(result1[3], 10);

    return new Date(year, month - 1, date);
  }

  const result2 = dateMask2.exec(date);
  if (result2) {
    const date: number = parseInt(result2[1], 10);
    const month: number = parseInt(result2[2], 10);
    const year: number = parseInt(result2[3], 10);

    return new Date(year, month - 1, date);
  }

  const result3 = dateMask3.exec(date);
  if (result3) {
    const date: number = parseInt(result3[1], 10);
    const month: number = parseInt(result3[2], 10);
    const year: number = new Date().getFullYear();

    return new Date(year, month - 1, date);
  }

  return null;
}

/**
 * Converts a string to an object of type T or null
 * @param obj the string to be converted to an object of type T
 * @returns the converted object or null if string is empty
 */
export function stringToType<T>(obj: string): T | null {
  if (obj) { return obj as unknown as T }
  return null;
}

/**
 * Converts an object of type T to its string representation
 * @param obj the object to be converted to a string
 * @returns the string representing the converted object
 */
export function typeToString<T>(obj: T | null): string {
  if (obj) { return (obj as any).toString() }
  return '';
}

/**
 * Converts an enum to an array of strings
 * @param obj the enum that will be converted to a string array
 * @returns the string array that represents the enum
 */
export function enumToArray(obj: any): string[] {
  return Object.values(obj).filter(type => isNaN(type as any)) as string[];
}

/**
 * Searches inside an enum, looking for values that 
 * @param query the string to use as a search inside the enum
 * @param objEnum the corresponding enum value
 */
export function searchInEnum<T>(query: string, objEnum: any): T | null {
  const enumValues = enumToArray(objEnum);
  const normEnumValues = enumValues.map((value: string) => removeAccents(value).toLowerCase());
  const index = normEnumValues.findIndex((value: string) =>
    value.toLowerCase().startsWith(removeAccents(query).toLowerCase())
  );

  if (index >= 0) {
    return stringToType<T>(enumValues[index]);
  }

  return null;
}

/**
 * Opens a download file window to download a provided Blob file
 * @param blob the blob to be downloaded as a file
 * @param name the name of the file to be downloaded
 */
export function downloadBlob(blob: Blob, name: string): void {
  const blobUrl = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = blobUrl;
  link.download = name;
  document.body.appendChild(link);

  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window
    })
  );

  document.body.removeChild(link);
}

/**
 * Converts a javascript object to its plain-old Javascript object (POJO)
 * @param obj the object to be converted
 * @returns the object as a plain-old Javascript object (POJO)
 */
export function objectToPojo<T>(obj: any): T {
  return JSON.parse(JSON.stringify(obj)) as T;
}

/**
 * Remove accents from strings
 * @param text the string that will have accents removed
 * @return the string without any accent
 */
export function removeAccents(text: string): string {
  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
}

/**
 * Extracts the first ocurrence of a textual element in a string
 * @param phrase the phrase from where to extract the text
 * @returns the first ocurrence of a text in the phrase string
 */
export function extractFirstText(phrase: string): string | null {
  const regex = /[a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸ]+/;
  const result = regex.exec(phrase);

  if (result) {
    return result[0];
  }

  return null;
}
