import { BaseService } from './base/base-service';
import { MarketDb } from "@/database/market-db";
import { Config } from '@/models/base/config.model';

/**
 * Class that represents a collection of methods related to config operations
 */
export class ConfigService extends BaseService {
  /**
   * Constructor
   */
  constructor(db: MarketDb) {
    // call super
    super(db);
  }

  /**
   * Gets the config object from the database
   * @returns a promise that resolves to a config object or undefined
   */
  public async get(): Promise<Config | undefined> {
    return this.db.config.get(Config.configKey);
  }

  /**
   * Posts or Puts a config object into the database
   * @param config the config object to be posted
   * @returns a promise that resolves to the generated or updated ID
   */
  public async postOrPut(config: Config): Promise<number> {
    return this.db.config.put(config);
  }

  /**
   * Deletes the config object from the database
   * @returns returns an empty promise
   */
  public async delete(): Promise<void> {
    return this.db.config.delete(Config.configKey);
  }
}
