<template>
  <div
    class="position-fixed bottom-0 end-0 p-1 p-sm-2 p-md-3 p-lg-4"
    style="z-index: 5"
  >
    <div
      class="toast align-items-center text-white bg-primary border-0"
      :id="toastId"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div class="d-flex">
        <div class="toast-body">{{ message }}</div>
        <button
          type="button"
          class="btn-close btn-close-white me-2 m-auto"
          data-bs-dismiss="toast"
          aria-label="Close"
        ></button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import { Toast } from "bootstrap";

export default defineComponent({
  name: "Toast",
  props: {
    toastId: { type: String, required: true },
  },
  setup(props) {
    const message = ref<string | null>(null);
    let toast: Toast | null;

    onMounted(() => {
      const toastElement = document.getElementById(props.toastId);
      toast = toastElement ? new Toast(toastElement) : null;
    });

    onUnmounted(() => {
      if (toast) {
        toast.dispose();
      }
    });

    /**
     * Shows the toast
     * @param messageData the message to show in the toast
     */
    const open = (messageData: string): void => {
      if (toast) {
        message.value = messageData;
        toast.show();
      } else {
        throw new Error("Could not find the toast to show");
      }
    };

    /**
     * Hides the toast
     */
    const close = (): void => {
      if (toast) {
        toast.hide();
      } else {
        throw new Error("Could not find the toast to hide");
      }
    };

    // expose template variables
    return { message, open, close };
  },
});
</script>
