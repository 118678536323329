
import { computed, defineComponent, onMounted, onUnmounted, ref } from "vue";
import { ModalMode } from "@/models/enums/modal-mode.enum";
import { Customer } from "@/models/base/customer.model";
import { Modal } from "bootstrap";
import { CustomerForm } from "@/models/forms/customer.form";
import { CustomerService } from "@/services/customer-service";
import { db } from "@/database/market-db";

export default defineComponent({
  name: "CustomerModal",
  props: {
    modalId: { type: String, required: true },
  },
  emits: ["created", "updated"],
  setup(props, context) {
    const mode = ref(ModalMode.CREATE);
    const customer = ref(new CustomerForm());
    const customerService = new CustomerService(db);
    const wasValidated = ref(false);
    let customerModal: Modal | null;

    onMounted(() => {
      const modalElement = document.getElementById(props.modalId);
      const inputElement = document.getElementById("customerName");
      customerModal = modalElement ? new Modal(modalElement) : null;
      modalElement?.addEventListener("shown.bs.modal", () =>
        inputElement?.focus()
      );
    });

    onUnmounted(() => {
      if (customerModal) {
        customerModal.dispose();
      }
    });

    /**
     * Computes the title of the modal
     */
    const title = computed(() => {
      if (mode.value === ModalMode.EDIT) return "Editar Cliente";
      if (mode.value === ModalMode.CREATE) return "Cadastrar Cliente";
      throw new Error("Could not get the title from the specified modal mode");
    });

    /**
     * Computes the label of the confirmation button of the modal
     */
    const confirmationLabel = computed(() => {
      if (mode.value === ModalMode.EDIT) return "Atualizar";
      if (mode.value === ModalMode.CREATE) return "Cadastrar";
      throw new Error(
        "Could not get the confirmation label from the specified modal mode"
      );
    });

    /**
     * Open the modal in a specific mode and with specific data
     * @param openMode the mode to open the modal
     * @param openCustomer the customer to load in the modal
     */
    const open = (openMode: ModalMode, openCustomer?: Customer): void => {
      mode.value = openMode;
      customer.value = new CustomerForm(openCustomer);

      if (customerModal) {
        customerModal.show();
      } else {
        throw new Error("Could not find the modal to open");
      }
    };

    /**
     * Close the modal
     */
    const close = (): void => {
      if (customerModal) {
        customerModal.hide();
      } else {
        throw new Error("Could not find the modal to close");
      }
    };

    /**
     * Handle the click in the confirmation button
     */
    const onConfirmation = (): void => {
      // form validation
      if (!validate()) return;

      // on confirmation, if valid
      const customerToPut = new Customer(customer.value);
      customerService.postOrPut(customerToPut).then(() => {
        switch (mode.value) {
          case ModalMode.CREATE:
            context.emit("created");
            break;
          case ModalMode.EDIT:
            context.emit("updated");
            break;
          default:
            throw new Error("Unknown mode for the customer modal");
        }

        close();
      });
    };

    /**
     * Validate the user input before submitting
     */
    const validate = (): boolean => {
      const customerNameInput = document.getElementById(
        "customerName"
      ) as HTMLInputElement;

      if (!customerNameInput.validity.valid) {
        customerNameInput.focus();
        wasValidated.value = true;
        return false;
      }

      wasValidated.value = false;
      return true;
    };

    return {
      title,
      confirmationLabel,
      open,
      close,
      customer,
      onConfirmation,
      wasValidated,
    };
  },
});
