
import { defineComponent, onMounted, onUnmounted, Ref, ref } from "vue";
import { Modal } from "bootstrap";
import { ModalMode } from "@/models/enums/modal-mode.enum";
import { Category } from "@/models/base/category.model";
import { CategoryService } from "@/services/category-service";
import { db } from "@/database/market-db";

export default defineComponent({
  name: "CategoryModal",
  props: {
    modalId: { type: String, required: true },
  },
  emits: ["updated"],
  setup(props, context) {
    const mode = ref(ModalMode.CREATE);
    const categories = ref(new Array<Category>());
    const categoryName = ref("");
    const categoryRename = ref("");
    const categoryDelete = ref("");
    const categoryEditId: Ref<number | null> = ref(null);
    const categoryDeleteId: Ref<number | null> = ref(null);
    const categoryService = new CategoryService(db);
    let categoryModal: Modal | null;

    onMounted(() => {
      const modalElement = document.getElementById(props.modalId);
      const inputElement = document.getElementById("categoryName");
      categoryModal = modalElement ? new Modal(modalElement) : null;
      modalElement?.addEventListener("shown.bs.modal", () =>
        inputElement?.focus()
      );
    });

    onUnmounted(() => {
      if (categoryModal) {
        categoryModal.dispose();
      }
    });

    /**
     * Opens the modal
     */
    const open = (): void => {
      getCategories();
      mode.value = ModalMode.CREATE;
      categoryName.value = "";
      if (categoryModal) {
        categoryModal.show();
      } else {
        throw new Error("Could not find the modal to open");
      }
    };

    /**
     * Closes the modal
     */
    const close = (): void => {
      if (categoryModal) {
        categoryModal.hide();
      } else {
        throw new Error("Could not find the modal to close");
      }
    };

    /**
     * Create a category based on the provided name
     */
    const createCategory = (): void => {
      if (!categoryName.value) return;
      categoryService
        .postOrPut(Category.fromName(categoryName.value))
        .then(() => {
          categoryName.value = "";
          getCategories();
        });
    };

    /**
     * Makes the modal enter in edit mode for a specific category
     * @param id the id of the category to edit
     */
    const goToEditionMode = (id: number): void => {
      const category = categories.value.find(
        (category: Category) => category.id === id
      );
      if (category) {
        categoryEditId.value = id;
        categoryRename.value = category.name;
        mode.value = ModalMode.EDIT;
      }
    };

    /**
     * Makes the modal enter in delete mode for a specific category
     * @param id the id of the category to delete
     */
    const goToDeletionMode = (id: number): void => {
      const category = categories.value.find(
        (category: Category) => category.id === id
      );
      if (category) {
        categoryDeleteId.value = id;
        categoryDelete.value = category.name;
        mode.value = ModalMode.DELETE;
      }
    };

    /**
     * Renames the category to the new name provided
     */
    const renameCategory = (): void => {
      if (categoryEditId.value) {
        categoryService
          .postOrPut(
            Category.fromIdAndName(categoryEditId.value, categoryRename.value)
          )
          .then(() => {
            getCategories();
          });
      }
    };

    /**
     * Renames the category to the new name provided
     */
    const deleteCategory = (): void => {
      if (categoryDeleteId.value) {
        categoryService.delete(categoryDeleteId.value).then(() => {
          getCategories();
        });
      }
    };

    /**
     * Cancel the deletion and returns to the creation mode
     */
    const cancelDeletion = (): void => {
      mode.value = ModalMode.CREATE;
    };

    /**
     * Gets all the categories from the database
     */
    const getCategories = (): void => {
      categoryService.getAll().then((result: Category[]) => {
        categories.value = result;
        mode.value = ModalMode.CREATE;
        context.emit("updated");
      });
    };

    // exposing template variables
    return {
      mode,
      open,
      close,
      categories,
      categoryName,
      categoryRename,
      categoryDelete,
      createCategory,
      goToEditionMode,
      goToDeletionMode,
      renameCategory,
      deleteCategory,
      cancelDeletion,
      ModalMode,
    };
  },
});
