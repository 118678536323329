<template>
  <main class="container-fluid flex-fill overflow-auto p-3">
    <!-- Title Section -->
    <section class="mb-4">
      <h4>
        <i class="bi bi-gear me-2"></i>
        <span>Configurações</span>
      </h4>
      <h6>Gerencie as opções da aplicação</h6>
    </section>

    <!-- Personal Data -->
    <section class="mb-4">
      <h4>Dados pessoais</h4>
      <h6 class="mb-3">
        Digite seus dados pessoais para personalizar a página inicial
      </h6>

      <div class="mb-3 mw-35-rem">
        <label for="username" class="form-label">Seu nome de usuário</label>
        <div class="input-group">
          <span class="input-group-text">@</span>
          <input
            class="form-control"
            id="username"
            placeholder="Digite seu nome de usuário"
            v-model="config.username"
          />
        </div>
      </div>

      <div class="mb-4 mw-35-rem">
        <label for="storename" class="form-label"
          >Nome do estabelecimento</label
        >
        <div class="input-group">
          <span class="input-group-text">@</span>
          <input
            class="form-control"
            id="storename"
            placeholder="Digite o nome do estabelecimento"
            v-model="config.storename"
          />
        </div>
      </div>

      <button class="btn btn-secondary me-2 mb-2" @click="updatePersonalData()">
        <span>Atualizar Dados Pessoais</span>
      </button>
    </section>

    <!-- Export Data Section -->
    <section class="mb-4">
      <h4>Exportar dados</h4>
      <h6 class="mb-3">
        Salve todos os dados da aplicação em um arquivo arquivo
      </h6>
      <button class="btn btn-secondary me-2 mb-2" @click="exportDatabase()">
        <span>Exportar Dados</span>
      </button>
    </section>

    <!-- Import Data Section -->
    <section>
      <h4>Importar dados</h4>
      <h6 class="mb-3">
        Faça a importação dos dados da aplicação carregando um backup anterior
      </h6>
      <div class="input-group mb-3 mw-35-rem">
        <input
          type="file"
          class="form-control"
          id="importFile"
          @change="handleImportInputChange($event)"
        />
      </div>
      <button
        :disabled="!canImport"
        class="btn btn-secondary me-2"
        @click="openConfirmationModalForImport()"
      >
        <span>Importar Dados</span>
      </button>
    </section>

    <!-- Modal to delete sales -->
    <ConfirmationModal
      ref="confirmationModal"
      :modalId="'confirmationModal'"
      :title="'Confirmar importação de dados'"
      :confirmationLabel="'Sim, excluir meus dados e importar'"
      @confirmed="importDatabase()"
    >
      <div>
        <div>
          <i class="bi diamond-exclamation"></i>
        </div>
        <div>
          Atenção! Os dados da aplicação serão
          <strong>sobrescritos permanentemente</strong> pelos novos dados
          importados.
        </div>
        <div>Esta ação é irreversível. Deseja continuar?</div>
      </div>
    </ConfirmationModal>

    <!-- Toast that handles feedback from operations -->
    <Toast ref="feedbackToast" :toastId="'toast'" />
  </main>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { ConfigService } from "@/services/config-service";
import { exportDB, importDB, ImportOptions } from "dexie-export-import";
import { db } from "@/database/market-db";
import { Config } from "@/models/base/config.model";
import { dateToString, downloadBlob, objectToPojo } from "@/utils/util";
import Toast from "@/components/base/Toast.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";

export default defineComponent({
  name: "Config",
  components: {
    Toast,
    ConfirmationModal,
  },
  setup() {
    const configService = new ConfigService(db);
    const config = ref(Config.fromBlank());
    const canImport = ref(false);
    const feedbackToast = ref<InstanceType<typeof Toast>>();
    const confirmationModal = ref<InstanceType<typeof ConfirmationModal>>();

    configService.get().then((result: Config | undefined) => {
      if (result) {
        config.value = result;
      }
    });

    /**
     * Export the contents of the database as a file
     */
    const exportDatabase = async (): Promise<void> => {
      const blob = await exportDB(db);
      const date = new Date();
      feedbackToast.value?.open("Os dados foram exportados com sucesso");
      downloadBlob(blob, `market-${dateToString(date)}.json`);
    };

    /**
     * Import the database from the contents of a file
     */
    const importDatabase = async (): Promise<void> => {
      // get DOM element
      const element = document.getElementById("importFile");
      if (!element) {
        throw new Error("Could not find import file element in the DOM");
      }

      // get files from element
      const files = (element as HTMLInputElement).files;
      if (files && files.length === 0) {
        throw new Error("Could not find any file in the import file element");
      }

      // import the first file in the list
      if (files != null) {
        await importDB(
          files[0] as Blob,
          { clearTablesBeforeImport: true } as ImportOptions
        );
        feedbackToast.value?.open("Os dados foram importados com sucesso");
      }
    };

    /**
     * Updates the personal information data
     */
    const updatePersonalData = (): void => {
      configService.postOrPut(objectToPojo(config.value)).then(() => {
        feedbackToast.value?.open("Dados pessoais atualizados com sucesso");
      });
    };

    /**
     * Handles the import of the database by asking the user for confirmation
     */
    const openConfirmationModalForImport = (): void => {
      confirmationModal.value?.open();
    };

    /**
     * Handles the changes in the file import input
     */
    const handleImportInputChange = (event: Event): void => {
      const target = event.target as HTMLInputElement;
      canImport.value = target.files ? target.files.length > 0 : false;
    };

    return {
      config,
      exportDatabase,
      importDatabase,
      canImport,
      handleImportInputChange,
      feedbackToast,
      confirmationModal,
      openConfirmationModalForImport,
      updatePersonalData,
    };
  },
});
</script>

<style lang="scss" scoped>
.mw-35-rem {
  max-width: 35rem;
}
</style>