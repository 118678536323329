
import { computed, defineComponent, onMounted, onUnmounted, ref } from "vue";
import { ModalMode } from "@/models/enums/modal-mode.enum";
import { Sale } from "@/models/base/sale.model";
import { Modal } from "bootstrap";
import { SaleForm } from "@/models/forms/sale.form";
import { SaleService } from "@/services/sale-service";
import { db } from "@/database/market-db";
import { enumToArray } from "@/utils/util";
import { SaleType } from "@/models/enums/sale-type.enum";

export default defineComponent({
  name: "SaleModal",
  props: {
    modalId: { type: String, required: true },
  },
  emits: ["created", "updated"],
  setup(props, context) {
    const mode = ref(ModalMode.CREATE);
    const sale = ref(SaleForm.fromDefaults());
    const saleService = new SaleService(db);
    const saleTypes = enumToArray(SaleType);
    const wasValidated = ref(false);
    let saleModal: Modal | null;

    onMounted(() => {
      const modalElement = document.getElementById(props.modalId);
      const inputElement = document.getElementById("saleValue");
      saleModal = modalElement ? new Modal(modalElement) : null;
      modalElement?.addEventListener("shown.bs.modal", () =>
        inputElement?.focus()
      );
    });

    onUnmounted(() => {
      if (saleModal) {
        saleModal.dispose();
      }
    });

    /**
     * Computes the title of the modal
     */
    const title = computed(() => {
      if (mode.value === ModalMode.EDIT) return "Editar Venda";
      if (mode.value === ModalMode.CREATE) return "Inserir Venda";
      throw new Error("Could not get the title from the specified modal mode");
    });

    /**
     * Computes the label of the confirmation button of the modal
     */
    const confirmationLabel = computed(() => {
      if (mode.value === ModalMode.EDIT) return "Atualizar";
      if (mode.value === ModalMode.CREATE) return "Inserir";
      throw new Error(
        "Could not get the confirmation label from the specified modal mode"
      );
    });

    /**
     * Open the modal in a specific mode and with specific data
     * @param openMode the mode to open the modal
     * @param openSale the sale to load in the modal
     */
    const open = (openMode: ModalMode, openSale?: Sale): void => {
      mode.value = openMode;

      if (openSale) {
        sale.value = SaleForm.fromObj(openSale);
      } else {
        sale.value = SaleForm.fromDefaults();
      }

      if (saleModal) {
        saleModal.show();
      } else {
        throw new Error("Could not find the modal to open");
      }
    };

    /**
     * Close the modal
     */
    const close = (): void => {
      if (saleModal) {
        saleModal.hide();
      } else {
        throw new Error("Could not find the modal to close");
      }
    };

    /**
     * Handle the click in the confirmation button
     */
    const onConfirmation = (): void => {
      // form validation
      if (!validate()) return;

      // on confirmation, if valid
      const saleToPut = Sale.fromObj(sale.value);
      saleService.postOrPut(saleToPut).then(() => {
        switch (mode.value) {
          case ModalMode.CREATE:
            context.emit("created");
            break;
          case ModalMode.EDIT:
            context.emit("updated");
            break;
          default:
            throw new Error("Unknown mode for the sale modal");
        }

        close();
      });
    };

    /**
     * Validate the user input before submitting
     */
    const validate = (): boolean => {
      const valueInput = document.getElementById(
        "saleValue"
      ) as HTMLInputElement;

      if (!valueInput.validity.valid) {
        valueInput.focus();
        wasValidated.value = true;
        return false;
      }

      wasValidated.value = false;
      return true;
    };

    return {
      title,
      confirmationLabel,
      open,
      close,
      sale,
      onConfirmation,
      saleTypes,
      wasValidated,
    };
  },
});
