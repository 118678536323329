<template>
  <table class="table table-sm table-hover">
    <!-- Table header -->
    <thead>
      <tr>
        <th scope="col" class="px-2 text-nowrap sticky-top bg-white">Cód.</th>
        <th scope="col" class="px-2 text-nowrap sticky-top bg-white">
          Nome do produto
        </th>
        <th scope="col" class="px-2 text-nowrap sticky-top bg-white">Peso</th>
        <th scope="col" class="px-2 text-nowrap sticky-top bg-white">Custo</th>
        <th scope="col" class="px-2 text-nowrap sticky-top bg-white">Kg</th>
        <th scope="col" class="px-2 text-nowrap sticky-top bg-white">
          À vista
        </th>
        <th scope="col" class="px-2 text-nowrap sticky-top bg-white">
          À prazo
        </th>
        <th scope="col" class="px-2 text-nowrap sticky-top bg-white">
          Categoria
        </th>
        <th scope="col" class="px-2 text-nowrap sticky-top bg-white">
          Fornecedor
        </th>
        <th scope="col" class="px-2 text-nowrap sticky-top bg-white">Ações</th>
      </tr>
    </thead>

    <!-- Table body -->
    <tbody>
      <tr v-for="product in products" :key="product.id">
        <td class="px-2 text-nowrap">
          {{ product.id }}
        </td>
        <td class="px-2">
          {{ product.name }}
        </td>
        <td class="px-2 text-nowrap">
          {{ numberToString(product.weight, "", " kg") }}
        </td>
        <td class="px-2 text-nowrap">
          {{ numberToMoney(product.cost, "R$ ") }}
        </td>
        <td class="px-2 text-nowrap">
          {{ numberToMoney(product.inBulk, "R$ ") }}
        </td>
        <td class="px-2 text-nowrap">
          {{ numberToMoney(product.price1, "R$ ") }}
        </td>
        <td class="px-2 text-nowrap">
          {{ numberToMoney(product.price2, "R$ ") }}
        </td>
        <td class="px-2">
          {{ product.category }}
        </td>
        <td class="px-2">
          {{ product.provider }}
        </td>
        <td class="px-2 text-nowrap">
          <a class="cursor-pointer text-dark" @click="onEdit(product)">
            <i class="bi bi-pencil-square me-2"></i>
          </a>
          <a class="cursor-pointer text-dark" @click="onDelete(product)">
            <i class="bi bi-trash"></i>
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Product } from "@/models/base/product.model";
import { numberToMoney, numberToString } from "@/utils/util";

export default defineComponent({
  name: "ProductTable",
  props: {
    products: {
      type: Object as PropType<Product[]>,
      default: new Array<Product>(),
    },
  },
  emits: ["onEdit", "onDelete"],
  setup(props, context) {
    /**
     * Emits an event when the user clicks in the edit action
     * @param product the product associated with the edit action
     */
    const onEdit = (product: Product): void => {
      context.emit("onEdit", product);
    };

    /**
     * Emits an event when the user clicks in the delete action
     * @param product the product associated with the delete action
     */
    const onDelete = (product: Product): void => {
      context.emit("onDelete", product);
    };

    // expose template variables
    return { onEdit, onDelete, numberToMoney, numberToString };
  },
});
</script>
