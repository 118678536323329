
import { defineComponent, PropType } from "vue";
import { Product } from "@/models/base/product.model";
import { numberToMoney, numberToString } from "@/utils/util";

export default defineComponent({
  name: "ProductTable",
  props: {
    products: {
      type: Object as PropType<Product[]>,
      default: new Array<Product>(),
    },
  },
  emits: ["onEdit", "onDelete"],
  setup(props, context) {
    /**
     * Emits an event when the user clicks in the edit action
     * @param product the product associated with the edit action
     */
    const onEdit = (product: Product): void => {
      context.emit("onEdit", product);
    };

    /**
     * Emits an event when the user clicks in the delete action
     * @param product the product associated with the delete action
     */
    const onDelete = (product: Product): void => {
      context.emit("onDelete", product);
    };

    // expose template variables
    return { onEdit, onDelete, numberToMoney, numberToString };
  },
});
