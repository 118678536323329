
import { computed, defineComponent, onMounted, onUnmounted, ref } from "vue";
import { ModalMode } from "@/models/enums/modal-mode.enum";
import { Product } from "@/models/base/product.model";
import { Modal } from "bootstrap";
import { ProductForm } from "@/models/forms/product.form";
import { ProductService } from "@/services/product-service";
import { Category } from "@/models/base/category.model";
import { CategoryService } from "@/services/category-service";
import { db } from "@/database/market-db";
import { moneyToNumber, stringToNumber } from "@/utils/util";

export default defineComponent({
  name: "ProductModal",
  props: {
    modalId: { type: String, required: true },
  },
  emits: ["created", "updated"],
  setup(props, context) {
    const mode = ref(ModalMode.CREATE);
    const product = ref(new ProductForm());
    const categories = ref(new Array<Category>());
    const productService = new ProductService(db);
    const categoryService = new CategoryService(db);
    const wasValidated = ref(false);
    let productModal: Modal | null;

    onMounted(() => {
      const modalElement = document.getElementById(props.modalId);
      const inputElement = document.getElementById("productName");
      productModal = modalElement ? new Modal(modalElement) : null;
      modalElement?.addEventListener("shown.bs.modal", () =>
        inputElement?.focus()
      );
    });

    onUnmounted(() => {
      if (productModal) {
        productModal.dispose();
      }
    });

    /**
     * Computes the title of the modal
     */
    const title = computed(() => {
      if (mode.value === ModalMode.EDIT) return "Editar Produto";
      if (mode.value === ModalMode.CREATE) return "Cadastrar Produto";
      throw new Error("Could not get the title from the specified modal mode");
    });

    /**
     * Computes the label of the confirmation button of the modal
     */
    const confirmationLabel = computed(() => {
      if (mode.value === ModalMode.EDIT) return "Atualizar";
      if (mode.value === ModalMode.CREATE) return "Cadastrar";
      throw new Error(
        "Could not get the confirmation label from the specified modal mode"
      );
    });

    /**
     * Computes the margin in bulk by comparing the seller price with the cost
     */
    const marginInBulk = computed(() => {
      const weight = stringToNumber(product.value.weight);
      const inBulk = moneyToNumber(product.value.inBulk);
      const cost = moneyToNumber(product.value.cost);
      const sellerPrice = weight && inBulk ? weight * inBulk : null;
      const margin =
        sellerPrice && cost ? (sellerPrice / cost - 1) * 100 : null;
      return margin ? `${margin > 0 ? "+" : ""}${margin.toFixed(1)}%` : "";
    });

    /**
     * Computes the margin of the price 1 by comparing the seller price with the cost
     */
    const marginPrice1 = computed(() => {
      const cost = moneyToNumber(product.value.cost);
      const price1 = moneyToNumber(product.value.price1);
      const margin = price1 && cost ? (price1 / cost - 1) * 100 : null;
      return margin ? `${margin > 0 ? "+" : ""}${margin.toFixed(1)}%` : "";
    });

    /**
     * Computes the margin of the price 2 by comparing the seller price with the cost
     */
    const marginPrice2 = computed(() => {
      const cost = moneyToNumber(product.value.cost);
      const price2 = moneyToNumber(product.value.price2);
      const margin = price2 && cost ? (price2 / cost - 1) * 100 : null;
      return margin ? `${margin > 0 ? "+" : ""}${margin.toFixed(1)}%` : "";
    });

    /**
     * Open the modal in a specific mode and with specific data
     * @param openMode the mode to open the modal
     * @param openProduct the product to load in the modal
     */
    const open = (openMode: ModalMode, openProduct?: Product): void => {
      mode.value = openMode;
      product.value = new ProductForm(openProduct);

      if (productModal) {
        productModal.show();
        categoryService.getAll().then((result: Category[]) => {
          categories.value = result;
        });
      } else {
        throw new Error("Could not find the modal to open");
      }
    };

    /**
     * Close the modal
     */
    const close = (): void => {
      if (productModal) {
        productModal.hide();
      } else {
        throw new Error("Could not find the modal to close");
      }
    };

    /**
     * Handle the click in the confirmation button
     */
    const onConfirmation = (): void => {
      // form validation
      if (!validate()) return;

      // on confirmation, if valid
      const productToPut = new Product(product.value);
      productService.postOrPut(productToPut).then(() => {
        switch (mode.value) {
          case ModalMode.CREATE:
            context.emit("created");
            break;
          case ModalMode.EDIT:
            context.emit("updated");
            break;
          default:
            throw new Error("Unknown mode for the product modal");
        }

        close();
      });
    };

    /**
     * Validate the user input before submitting
     */
    const validate = (): boolean => {
      const productNameInput = document.getElementById(
        "productName"
      ) as HTMLInputElement;

      if (!productNameInput.validity.valid) {
        productNameInput.focus();
        wasValidated.value = true;
        return false;
      }

      wasValidated.value = false;
      return true;
    };

    return {
      title,
      confirmationLabel,
      open,
      close,
      product,
      categories,
      onConfirmation,
      marginInBulk,
      marginPrice1,
      marginPrice2,
      wasValidated,
    };
  },
});
