
import { defineComponent } from "vue";
import Header from "@/components/base/Header.vue";

export default defineComponent({
  name: "Layout",
  components: {
    Header,
  },
});
