import { CustomerForm } from "../forms/customer.form";
import { SelectableItem } from "./selectable-item.interface";

/**
 * Class that represents a customer
 */
export class Customer implements SelectableItem {
  id?: number;
  name: string;
  address: string;
  phone: string;

  constructor(customerForm: CustomerForm) {
    this.id = customerForm.id;
    this.name = customerForm.name;
    this.address = customerForm.address;
    this.phone = customerForm.phone;
  }
}
