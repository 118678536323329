import { BaseService } from './base/base-service';
import { MarketDb } from "@/database/market-db";
import { Customer } from '../models/base/customer.model';

/**
 * Class that represents a collection of methods related to customer operations
 */
export class CustomerService extends BaseService {
  /**
   * Constructor
   */
  constructor(db: MarketDb) {
    // call super
    super(db);
  }

  /**
   * Gets all customers from the database
   * @returns a promise that resolves to a list of all customers
   */
  public async getAll(): Promise<Customer[]> {
    return this.db.customers.toArray();
  }

  /**
   * Posts or Puts a new customer into the database
   * @param customer the customer to be posted
   * @returns a promise that resolves to the generated or updated ID
   */
  public async postOrPut(customer: Customer): Promise<number> {
    return this.db.customers.put(customer);
  }

  /**
   * 
   * @param id the id of the customer to be deleted
   * @returns returns an empty promise
   */
  public async delete(id: number): Promise<void> {
    return this.db.customers.delete(id);
  }

  /**
   * Searches the customer by name
   * @param name the name of the customer to be searched
   * @returns a promise that resolves to a list of customers filtered by name
   */
  public async searchByName(name: string): Promise<Customer[]> {
    return this.db.customers.filter((customer: Customer) =>
      customer.name.toLowerCase().includes(name.toLowerCase())).toArray();
  }
}
