import { numberToMoney, numberToString } from "@/utils/util";
import { EstimateItem } from "../base/estimate-item.model";

/**
 * Class that represents an estimate item form data
 */
export class EstimateItemForm {
  id?: number;
  name: string;
  quantity: string;
  price: string;

  constructor(estimateItem?: EstimateItem) {
    if (estimateItem) {
      this.id = estimateItem.id;
      this.name = estimateItem.name ?? '';
      this.quantity = numberToString(estimateItem.quantity);
      this.price = numberToMoney(estimateItem.price, "R$ ");
    } else {
      this.name = '';
      this.quantity = '0';
      this.price = 'R$ 0,00';
    }
  }
}
