/**
 * Class that represents the possible user configurations for the application
 */
export class Config {
  /**
   * The constant key used to store the configuration
   */
  static configKey = 1;

  id: number;
  username: string;
  storename: string;

  /**
   * Constructor
   */
  constructor(id: number, username: string, storename: string,) {
    this.id = id;
    this.username = username;
    this.storename = storename;
  }

  static fromBlank(): Config {
    return new Config(Config.configKey, '', '');
  }

  static fromNames(username: string, storename: string): Config {
    return new Config(Config.configKey, username, storename);
  }
}
