
import { defineComponent, ref } from "vue";
import { ConfigService } from "@/services/config-service";
import { exportDB, importDB, ImportOptions } from "dexie-export-import";
import { db } from "@/database/market-db";
import { Config } from "@/models/base/config.model";
import { dateToString, downloadBlob, objectToPojo } from "@/utils/util";
import Toast from "@/components/base/Toast.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";

export default defineComponent({
  name: "Config",
  components: {
    Toast,
    ConfirmationModal,
  },
  setup() {
    const configService = new ConfigService(db);
    const config = ref(Config.fromBlank());
    const canImport = ref(false);
    const feedbackToast = ref<InstanceType<typeof Toast>>();
    const confirmationModal = ref<InstanceType<typeof ConfirmationModal>>();

    configService.get().then((result: Config | undefined) => {
      if (result) {
        config.value = result;
      }
    });

    /**
     * Export the contents of the database as a file
     */
    const exportDatabase = async (): Promise<void> => {
      const blob = await exportDB(db);
      const date = new Date();
      feedbackToast.value?.open("Os dados foram exportados com sucesso");
      downloadBlob(blob, `market-${dateToString(date)}.json`);
    };

    /**
     * Import the database from the contents of a file
     */
    const importDatabase = async (): Promise<void> => {
      // get DOM element
      const element = document.getElementById("importFile");
      if (!element) {
        throw new Error("Could not find import file element in the DOM");
      }

      // get files from element
      const files = (element as HTMLInputElement).files;
      if (files && files.length === 0) {
        throw new Error("Could not find any file in the import file element");
      }

      // import the first file in the list
      if (files != null) {
        await importDB(
          files[0] as Blob,
          { clearTablesBeforeImport: true } as ImportOptions
        );
        feedbackToast.value?.open("Os dados foram importados com sucesso");
      }
    };

    /**
     * Updates the personal information data
     */
    const updatePersonalData = (): void => {
      configService.postOrPut(objectToPojo(config.value)).then(() => {
        feedbackToast.value?.open("Dados pessoais atualizados com sucesso");
      });
    };

    /**
     * Handles the import of the database by asking the user for confirmation
     */
    const openConfirmationModalForImport = (): void => {
      confirmationModal.value?.open();
    };

    /**
     * Handles the changes in the file import input
     */
    const handleImportInputChange = (event: Event): void => {
      const target = event.target as HTMLInputElement;
      canImport.value = target.files ? target.files.length > 0 : false;
    };

    return {
      config,
      exportDatabase,
      importDatabase,
      canImport,
      handleImportInputChange,
      feedbackToast,
      confirmationModal,
      openConfirmationModalForImport,
      updatePersonalData,
    };
  },
});
