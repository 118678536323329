<template>
  <table class="table table-sm table-hover">
    <!-- Table header -->
    <thead>
      <tr>
        <th scope="col" class="px-2 text-nowrap sticky-top bg-white">Valor</th>
        <th scope="col" class="px-2 text-nowrap sticky-top bg-white">Tipo</th>
        <th scope="col" class="px-2 text-nowrap sticky-top bg-white">Data</th>
        <th scope="col" class="px-2 text-nowrap sticky-top bg-white">Ações</th>
      </tr>
    </thead>

    <!-- Table body -->
    <tbody>
      <tr v-for="sale in sales" :key="sale.id">
        <td class="px-2 text-nowrap">
          {{ numberToMoney(sale.value, "R$ ") }}
        </td>
        <td class="px-2 text-nowrap">
          {{ typeToString(sale.type) }}
        </td>
        <td class="px-2 text-nowrap">
          {{ dateToString(sale.date) }}
        </td>
        <td class="px-2 text-nowrap">
          <a class="cursor-pointer text-dark" @click="onEdit(sale)">
            <i class="bi bi-pencil-square me-2"></i>
          </a>
          <a class="cursor-pointer text-dark" @click="onDelete(sale)">
            <i class="bi bi-trash"></i>
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Sale } from "@/models/base/sale.model";
import { numberToMoney, dateToString, typeToString } from "@/utils/util";

export default defineComponent({
  name: "SalesTable",
  props: {
    sales: {
      type: Object as PropType<Sale[]>,
      default: new Array<Sale>(),
    },
  },
  emits: ["onEdit", "onDelete"],
  setup(props, context) {
    /**
     * Emits an event when the user clicks in the edit action
     * @param sale the sale associated with the edit action
     */
    const onEdit = (sale: Sale): void => {
      context.emit("onEdit", sale);
    };

    /**
     * Emits an event when the user clicks in the delete action
     * @param product the sale associated with the delete action
     */
    const onDelete = (sale: Sale): void => {
      context.emit("onDelete", sale);
    };

    // expose template variables
    return { onEdit, onDelete, numberToMoney, dateToString, typeToString };
  },
});
</script>
