<template>
  <Transition appear name="fade">
    <div class="selectable-list border rounded bg-light shadow">
      <div class="list-group-flush">
        <a
          v-for="item of items"
          :key="item.id"
          href="#"
          class="list-group-item list-group-item-action"
          @click="onSelection(item.id)"
        >
          <div class="d-flex justify-content-between align-items-center">
            <span>{{ item.name }}</span>
            <small v-if="items.length === 1">
              <span class="me-2">Tecle Enter para Inserir</span>
              <i class="bi bi-arrow-return-left"></i>
            </small>
          </div>
        </a>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts">
import { SelectableItem } from "@/models/base/selectable-item.interface";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "SelectableList",
  props: {
    items: {
      type: Object as PropType<SelectableItem[]>,
      default: new Array<SelectableItem>(),
    },
  },
  emits: ["selected"],
  setup(props, context) {
    /**
     * Emits an event when the user selects an item
     */
    const onSelection = (itemId: number): void => {
      context.emit("selected", itemId);
    };

    return { onSelection };
  },
});
</script>

<style lang="scss" scoped>
@import "@/styles/global";

div.selectable-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1100;
  max-height: 14.5rem;
  overflow: auto;
}
</style>