
import { defineComponent, ref } from "vue";
import { ModalMode } from "@/models/enums/modal-mode.enum";
import { db } from "@/database/market-db";
import { EstimateService } from "@/services/estimate-service";
import { Estimate } from "@/models/base/estimate.model";
import EstimateTable from "@/components/tables/EstimateTable.vue";
import EstimateModal from "@/components/modals/EstimateModal.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import Toast from "@/components/base/Toast.vue";

export default defineComponent({
  name: "Estimates",
  components: {
    EstimateTable,
    EstimateModal,
    ConfirmationModal,
    Toast,
  },
  setup() {
    const query = ref("");
    const estimates = ref(new Array<Estimate>());
    const estimateService = new EstimateService(db);
    const estimateModal = ref<InstanceType<typeof EstimateModal>>();
    const confirmationModal = ref<InstanceType<typeof ConfirmationModal>>();
    const estimateCustomerToDelete = ref<string | null>(null);
    const estimateIdToDelete = ref<number | null>(null);
    const feedbackToast = ref<InstanceType<typeof Toast>>();

    /**
     * Opens the estimate modal in create mode
     */
    const openEstimateModalForCreation = (): void => {
      estimateModal.value?.open(ModalMode.CREATE);
    };

    /**
     * Opens the estimate modal in edit mode
     * @param estimate the estimate to be opened in the modal
     */
    const openEstimateModalForEdition = (estimate: Estimate): void => {
      estimateModal.value?.open(ModalMode.EDIT, estimate);
    };

    /**
     * Opens the confirmation modal for deletion
     * @param estimate the estimate to setted to deletion
     */
    const openConfirmationModalForDeletion = (estimate: Estimate): void => {
      estimateCustomerToDelete.value = estimate.customerName;
      estimateIdToDelete.value = estimate.id ?? null;
      confirmationModal.value?.open();
    };

    /**
     * Handles the user input in the query input field
     */
    const handleQueryInput = (): void => {
      updateEstimateData();
    };

    /**
     * Handles the creation of an estimate
     */
    const handleEstimateCreation = (): void => {
      updateEstimateData();
      feedbackToast.value?.open("Orçamento criado com sucesso");
    };

    /**
     * Handles the update of an estimate
     */
    const handleEstimateUpdate = (): void => {
      updateEstimateData();
      feedbackToast.value?.open("Orçamento atualizado com sucesso");
    };

    /**
     * Handles the deletion of an estimate
     */
    const handleEstimateDeletion = (): void => {
      if (estimateIdToDelete.value) {
        estimateService.delete(estimateIdToDelete.value).then(() => {
          updateEstimateData();
          feedbackToast.value?.open("Orçamento excluído com sucesso");
        });
      } else {
        throw new Error("Could not handle the estimate deletion");
      }
    };

    /**
     * Updates estimates data by fetching from the database
     */
    const updateEstimateData = (): void => {
      estimateService
        .searchByCustomerName(query.value)
        .then((result: Estimate[]) => {
          estimates.value = result;
        });
    };

    // Initialization
    updateEstimateData();

    return {
      query,
      estimates,
      estimateModal,
      openEstimateModalForCreation,
      openEstimateModalForEdition,
      openConfirmationModalForDeletion,
      confirmationModal,
      estimateCustomerToDelete,
      estimateIdToDelete,
      feedbackToast,
      handleEstimateDeletion,
      handleEstimateCreation,
      handleEstimateUpdate,
      handleQueryInput,
    };
  },
});
