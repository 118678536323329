<template>
  <!-- New Product Modal -->
  <div
    class="modal fade"
    :id="modalId"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    @keydown.enter="onConfirmation()"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <!-- Modal header -->
        <div class="modal-header bg-light">
          <h5 class="modal-title">{{ title }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
          ></button>
        </div>

        <!-- Modal body -->
        <div class="modal-body">
          <div class="row mb-3">
            <div :class="{ 'was-validated': wasValidated }" class="col-8">
              <label for="productName" class="form-label"
                >Nome do produto</label
              >
              <div class="input-group">
                <span class="input-group-text">@</span>
                <input
                  class="form-control rounded-end"
                  id="productName"
                  placeholder="Digite o nome do produto"
                  v-model="product.name"
                  required
                />
                <div class="invalid-feedback">Insira um nome válido</div>
              </div>
            </div>

            <div class="col-4">
              <label for="weight" class="form-label">Peso</label>
              <div class="input-group">
                <input
                  class="form-control"
                  id="weight"
                  placeholder="Peso"
                  v-model="product.weight"
                />
                <span class="input-group-text">kg</span>
              </div>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col">
              <label for="cost" class="form-label">Preço de custo</label>
              <div class="input-group">
                <span class="input-group-text">R$</span>
                <input
                  class="form-control"
                  id="cost"
                  placeholder="Preço de custo"
                  v-model="product.cost"
                />
              </div>
            </div>
            <div class="col">
              <label
                for="inBulk"
                class="
                  form-label
                  d-flex
                  justify-content-between
                  align-items-end
                "
              >
                <span>Preço por kg</span>
                <small>{{ marginInBulk }}</small>
              </label>
              <div class="input-group">
                <span class="input-group-text">R$</span>
                <input
                  class="form-control"
                  id="inBulk"
                  placeholder="Preço por kg"
                  v-model="product.inBulk"
                />
              </div>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col">
              <label
                for="price1"
                class="
                  form-label
                  d-flex
                  justify-content-between
                  align-items-end
                "
              >
                <span>Preço à vista</span>
                <small>{{ marginPrice1 }}</small>
              </label>
              <div class="input-group">
                <span class="input-group-text">R$</span>
                <input
                  class="form-control"
                  id="price1"
                  placeholder="Preço à vista"
                  v-model="product.price1"
                />
              </div>
            </div>
            <div class="col">
              <label
                for="price2"
                class="
                  form-label
                  d-flex
                  justify-content-between
                  align-items-end
                "
              >
                <span>Preço à prazo</span>
                <small>{{ marginPrice2 }}</small>
              </label>
              <div class="input-group">
                <span class="input-group-text">R$</span>
                <input
                  class="form-control"
                  id="price2"
                  placeholder="Preço à prazo"
                  v-model="product.price2"
                />
              </div>
            </div>
          </div>

          <div class="mb-3">
            <label for="category" class="form-label">Categoria</label>
            <select
              class="form-select"
              id="category"
              v-model="product.category"
            >
              <option
                v-for="category of categories"
                :key="category.id"
                :value="category.name"
              >
                {{ category.name }}
              </option>
            </select>
          </div>

          <div class="mb-3">
            <label for="provider" class="form-label">Fornecedor</label>
            <input
              class="form-control"
              id="provider"
              placeholder="Digite um fornecedor"
              v-model="product.provider"
            />
          </div>
        </div>

        <!-- Modal footer -->
        <div class="modal-footer bg-light">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Cancelar
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="onConfirmation()"
          >
            {{ confirmationLabel }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, onUnmounted, ref } from "vue";
import { ModalMode } from "@/models/enums/modal-mode.enum";
import { Product } from "@/models/base/product.model";
import { Modal } from "bootstrap";
import { ProductForm } from "@/models/forms/product.form";
import { ProductService } from "@/services/product-service";
import { Category } from "@/models/base/category.model";
import { CategoryService } from "@/services/category-service";
import { db } from "@/database/market-db";
import { moneyToNumber, stringToNumber } from "@/utils/util";

export default defineComponent({
  name: "ProductModal",
  props: {
    modalId: { type: String, required: true },
  },
  emits: ["created", "updated"],
  setup(props, context) {
    const mode = ref(ModalMode.CREATE);
    const product = ref(new ProductForm());
    const categories = ref(new Array<Category>());
    const productService = new ProductService(db);
    const categoryService = new CategoryService(db);
    const wasValidated = ref(false);
    let productModal: Modal | null;

    onMounted(() => {
      const modalElement = document.getElementById(props.modalId);
      const inputElement = document.getElementById("productName");
      productModal = modalElement ? new Modal(modalElement) : null;
      modalElement?.addEventListener("shown.bs.modal", () =>
        inputElement?.focus()
      );
    });

    onUnmounted(() => {
      if (productModal) {
        productModal.dispose();
      }
    });

    /**
     * Computes the title of the modal
     */
    const title = computed(() => {
      if (mode.value === ModalMode.EDIT) return "Editar Produto";
      if (mode.value === ModalMode.CREATE) return "Cadastrar Produto";
      throw new Error("Could not get the title from the specified modal mode");
    });

    /**
     * Computes the label of the confirmation button of the modal
     */
    const confirmationLabel = computed(() => {
      if (mode.value === ModalMode.EDIT) return "Atualizar";
      if (mode.value === ModalMode.CREATE) return "Cadastrar";
      throw new Error(
        "Could not get the confirmation label from the specified modal mode"
      );
    });

    /**
     * Computes the margin in bulk by comparing the seller price with the cost
     */
    const marginInBulk = computed(() => {
      const weight = stringToNumber(product.value.weight);
      const inBulk = moneyToNumber(product.value.inBulk);
      const cost = moneyToNumber(product.value.cost);
      const sellerPrice = weight && inBulk ? weight * inBulk : null;
      const margin =
        sellerPrice && cost ? (sellerPrice / cost - 1) * 100 : null;
      return margin ? `${margin > 0 ? "+" : ""}${margin.toFixed(1)}%` : "";
    });

    /**
     * Computes the margin of the price 1 by comparing the seller price with the cost
     */
    const marginPrice1 = computed(() => {
      const cost = moneyToNumber(product.value.cost);
      const price1 = moneyToNumber(product.value.price1);
      const margin = price1 && cost ? (price1 / cost - 1) * 100 : null;
      return margin ? `${margin > 0 ? "+" : ""}${margin.toFixed(1)}%` : "";
    });

    /**
     * Computes the margin of the price 2 by comparing the seller price with the cost
     */
    const marginPrice2 = computed(() => {
      const cost = moneyToNumber(product.value.cost);
      const price2 = moneyToNumber(product.value.price2);
      const margin = price2 && cost ? (price2 / cost - 1) * 100 : null;
      return margin ? `${margin > 0 ? "+" : ""}${margin.toFixed(1)}%` : "";
    });

    /**
     * Open the modal in a specific mode and with specific data
     * @param openMode the mode to open the modal
     * @param openProduct the product to load in the modal
     */
    const open = (openMode: ModalMode, openProduct?: Product): void => {
      mode.value = openMode;
      product.value = new ProductForm(openProduct);

      if (productModal) {
        productModal.show();
        categoryService.getAll().then((result: Category[]) => {
          categories.value = result;
        });
      } else {
        throw new Error("Could not find the modal to open");
      }
    };

    /**
     * Close the modal
     */
    const close = (): void => {
      if (productModal) {
        productModal.hide();
      } else {
        throw new Error("Could not find the modal to close");
      }
    };

    /**
     * Handle the click in the confirmation button
     */
    const onConfirmation = (): void => {
      // form validation
      if (!validate()) return;

      // on confirmation, if valid
      const productToPut = new Product(product.value);
      productService.postOrPut(productToPut).then(() => {
        switch (mode.value) {
          case ModalMode.CREATE:
            context.emit("created");
            break;
          case ModalMode.EDIT:
            context.emit("updated");
            break;
          default:
            throw new Error("Unknown mode for the product modal");
        }

        close();
      });
    };

    /**
     * Validate the user input before submitting
     */
    const validate = (): boolean => {
      const productNameInput = document.getElementById(
        "productName"
      ) as HTMLInputElement;

      if (!productNameInput.validity.valid) {
        productNameInput.focus();
        wasValidated.value = true;
        return false;
      }

      wasValidated.value = false;
      return true;
    };

    return {
      title,
      confirmationLabel,
      open,
      close,
      product,
      categories,
      onConfirmation,
      marginInBulk,
      marginPrice1,
      marginPrice2,
      wasValidated,
    };
  },
});
</script>
